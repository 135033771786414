import React from "react";
import TableExport from "../../components/TableExport";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import useAuth from "../../auth/useAuth";

import "jspdf-autotable";
import { columns, options } from "../../hooks/getPdfLicences";

export default function MyLicences() {
  const { user } = useAuth();
  //console.log('user current: ', user.role.institutions);
  // user.role.institutions
  const { loading, error, data } = useQuery(GET_LICENCES, {
    variables: { institutionId: user.role?.institutions[0] },
  });

  //console.log("data: ", data);

  return (
    <>
      {/* <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Instituciones
                    </ListSubheader>
                }>
                
            </List> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "90vw",
          height: "80vh",
        }}
      >
        <TableExport
          options={options}
          columns={columns}
          data={data?.getLicencesByInst?.licences}
          title={"Listado de Licencias"}
        />
      </div>
    </>
  );
}

const GET_LICENCES = gql`
  query getLicencesByInst($institutionId: ID!) {
    getLicencesByInst(institutionId: $institutionId) {
      id
      name
      licences {
        id
        key
        dateExp
        typeLicence
        user {
          email
        }
      }
    }
  }
`;
