import React, { useState } from "react";
import { Button, Dimmer, Header, Image, Progress } from "semantic-ui-react";

function cardContent(lab, perc) {
  return (
    <div>
      <Header as="h3" inverted>
        {lab}
      </Header>

      <h2>{perc}%</h2>
    </div>
  );
}

const DimmerExampleEvents = (props) => {
  const [active, setActive] = useState(false);

  const handleShow = () => setActive(true);
  const handleHide = () => setActive(false);

  let content = cardContent(props.lab, props.percentaje);
  return (
    <div align="center">
      {/* <br></br> */}
      <Header /* as="h3" */ textAlign="center">
        <Progress
          percent={props.percentaje}
          progress
          style={{ background: "#e3e3e3	", color: "white" }}
        />
      </Header>
      {/*     <Dimmer.Dimmable
        centered
        as={Image}
        dimmed={active}
        dimmer={{ active, content }}
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        size="small"
        src={props.urlImg}
      ></Dimmer.Dimmable> */}
    </div>
  );
};
export default DimmerExampleEvents;
