import React, { useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { AuthContext } from "../context/auth";
import { useForm } from "../util/hooks";

import Swal from "sweetalert2";
import useAuth from "../auth/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "50px ",
    borderRadius: 8,
    boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
    background: "rgba( 255, 255, 255, 0.7 )",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#347EBF",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    color: "white",
    backgroundColor: "#347EBF",
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      backgroundColor: "#347EBF",
      transform: "scale(1.02);",
    },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#347ebf",
      },
    },
  },
}));

export default function ActiveLicence(props) {
  const classes = useStyles();

  const context = useContext(AuthContext);
  //const { user, logout } = useContext(AuthContext);
  const { user } = useAuth();
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(createRequestLicences, {
    key: "",
  });

  const [addRequest, { loading }] = useMutation(ASSIGN_USER_LICENCE, {
    update(_, { data: { assignUserLicence: userData } }) {
      //context.login(userData);
      Swal.fire({
        title: "Exito!",
        text: "Licencia activada correctamente!",
        icon: "success",
      });

      //context.login(userData);
      //history.push('/');
    },
    onError(err) {
      console.log(err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
      //debugger;
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: {
      key: values.key,
      idUser: user?.id,
    },
  });

  function createRequestLicences() {
    addRequest();
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Activar Licencia
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} noValidate>
          <TextField
            className={classes.select}
            style={{ background: "white" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="key"
            label="Clave Única de Licencia"
            name="key"
            onChange={onChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Activar
          </Button>
          <Grid container>
            <Grid item xs>
              <div>
                
                  Tienes problema para activar tu licencia? Contáctanos
               
              </div>
              <div>
                <Link variant="body2">
                  fanlab@faniot.com.ar
                </Link>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </div>
  );
}

const ASSIGN_USER_LICENCE = gql`
  mutation assignUserLicence($idUser: ID!, $key: String!) {
    assignUserLicence(idUser: $idUser, key: $key) {
      id
      dateExp
      typeLicence
      institution {
        name
        address
      }
    }
  }
`;
