import React, { useState } from 'react'
import { Button, Dimmer, Header, Image } from 'semantic-ui-react'


function cardContent(lab){
    return (
        <div>
        <Header as='h2' inverted>
            {lab}
        </Header>

        <Button primary>Explorar</Button>
        </div>
    )
}

const DimmerExampleEvents = (props) => {
    const [active, setActive] = useState(false);
    
    const handleShow = () => setActive(true)
    const handleHide = () => setActive(false)
    
    let content = cardContent(props.lab);
    return (
      <Dimmer.Dimmable centered
        as={Image}
        dimmed={active}
        dimmer={{ active, content }}
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        size='medium'
        src={props.urlImg}
      />
    )
}
export default DimmerExampleEvents;
 