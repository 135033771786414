/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox({ list, width, label, onChange, defaultValue, key, getOptionLabel }) {

  //console.log('list en select',list);
  return (
    <Autocomplete
      id="combo-box-demo"
      key={key}
      options={list}
      defaultValue={defaultValue}
      onChange={onChange}
      disableClearable
      
      getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.nombre}
      style={{ width: width, color:" #347eb" }}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined"  color="success"  />}
    />
  );
}