import React, { useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { useForm } from "../util/hooks";
import { FETCH_POSTS_QUERY } from "../util/graphql";
import Swal from "sweetalert2";
import Select from "./Select";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const provincesFetch = require("../jsonModel/Provinces.json");
const citiesFetch = require("../jsonModel/Cities.json");

const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#347ebf",
      },
    },
  },
});

function PostForm() {
  const [provinceSelected, setProvinceSelected] = useState(false);
  const [keyProvinceSelected, setKeyProvinceSelected] = useState(1);

  const [citySelected, setCitySelected] = useState(false);
  const [keyCitySelected, setKeyCitySelected] = useState(1);


  const { values, onChange, onSubmit } = useForm(createInstitutionCallback, {
    name: "",
    address: "",
    nameOrganism: "",
    country: "",
    province: "",
    city: "",
  });

  const [createInstitution, { error }] = useMutation(CREATE_POST_MUTATION, {
    variables: {
      name: values.name,
      address: values.address,
      provinceId: `${provinceSelected.id}`,
      cityId: `${citySelected.id}`,
    },
    update(proxy, result) {
      Swal.fire({
        title: "Success",
        text: "Institucion creada correctamente!",
        icon: "success",
      });
      /* const data = proxy.readQuery({
        query: FETCH_POSTS_QUERY
      });
      data.getInstitutions = [result.data.createInstitution,
         ...data.getInstitutions];
      proxy.writeQuery({ query: FETCH_POSTS_QUERY, data }); */
      values.name = "";
      values.address = "";
      values.nameOrganism = "";
      values.country = "";
      values.province = "";
      values.city = "";
      setCitySelected(false);
      setKeyCitySelected(keyCitySelected + 1);
      setKeyProvinceSelected(keyProvinceSelected + 1);
      setProvinceSelected(false);
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log(err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
    },
  });

  function createInstitutionCallback() {
    if (
      !values.name ||
      !values.address ||
      !provinceSelected?.id ||
      !citySelected?.id
    ) {
      //console.log();
      Swal.fire({
        title: "Error",
        text: "Por favor rellenar todos los campos requeridos!",
        icon: "error",
      });
    } else {
      createInstitution();
      /* console.log(
        "completo todos los campos",
        values.name,
        values.address,
        provinceSelected.id,
        citySelected.id
      ); */
    }
  }

  /*   useEffect(() => {
      console.log(provincesFetch)
      console.log('cities: ', citiesFetch)
    }, []) */

  const selectProvince = (item) => {
    // por si quita una provincia hay que perder la referencia de la localidad para que no mande el id viejo
    setCitySelected(false);
    setProvinceSelected(item);
    if (!item) setCitySelected(false);
  };

  const classes = useStyles();

  const onChangeProvince = (event, newValue) => {
    //console.log("selectedeeeee: ", newValue);
    setProvinceSelected({
      id: newValue?.id,
      name: newValue?.name ? newValue?.name : newValue?.nombre,
    });
  };

  const onChangeCity = (event, newValue) => {
    //console.log("selected City: ", newValue);
    setCitySelected({
      id: newValue?.id,
      name: newValue?.name ? newValue?.name : newValue?.nombre,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Form
        onSubmit={onSubmit}
        style={{
          borderRadius: "10px",
          padding: "30px",
          boxShadow: "4px 0px 10px -8px rgba(0,0,0,0.96)",
          background: "rgba(255,255,255,0.8)",
          minWidth: "500px",
          width: "800px",
        }}
      >
        <h2>Crear una Institución:</h2>
        <Form.Field>
          <Form.Input
            placeholder="Nombre"
            name="name"
            onChange={onChange}
            value={values.name}
            error={error ? true : false}
            required
          />
          <Form.Input
            placeholder="Dirección"
            name="address"
            onChange={onChange}
            value={values.address}
            error={error ? true : false}
            required
          />
          {/* <Form.Input
            placeholder="Organismo"
            name="nameOrganism"
            onChange={onChange}
            value={values.nameOrganism}
            error={error ? true : false}
          /> */}
          {/* <Form.Input
            placeholder="País"
            name="country"
            onChange={onChange}
            value={'Argentina'}
            error={error ? true : false}
          /> */}
          <Form.Field className={classes.select}>
            <Select
              label="Provincia"
              list={provincesFetch}
              onChange={onChangeProvince}
              key={keyProvinceSelected}
            />
          </Form.Field>
          {provinceSelected?.id && (
            <Form.Field className={classes.select}>
              <Select
                label="Localidad"
                list={citiesFetch.filter(function (localidad) {
                  return localidad.provincia.id === provinceSelected.id;
                })}
                onChange={onChangeCity}
                key={keyCitySelected}
              />
            </Form.Field>
          )}
          {/* <Form.Input
            placeholder="Provincia"
            name="province"
            onChange={onChange}
            value={values.province}
            error={error ? true : false}
          />
          <Form.Input
            placeholder="Localidad"
            name="city"
            onChange={onChange}
            value={values.city}
            error={error ? true : false}
          /> */}
          <Button
            className="btn"
            type="submit"
            style={{
              color: "white",
              background: "#347EBF",
            }}
          >
            Crear
          </Button>
        </Form.Field>
        <br></br>
      </Form>
      {error && (
        <div className="ui error message" style={{ marginBottom: 20 }}>
          <ul className="list">
            <li>{error.message}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

const CREATE_POST_MUTATION = gql`
  mutation createInstitution(
    $name: String!
    $address: String!
    $provinceId: String!
    $cityId: String!
  ) {
    createInstitution(
      input: {
        name: $name
        address: $address
        location: { provinceId: $provinceId, cityId: $cityId }
      }
    ) {
      id
      name
      address
    }
  }
`;

export default PostForm;
