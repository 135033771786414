import React from "react";
import { useHistory } from "react-router";
import useAuth from "../auth/useAuth";
import roles from "../helpers/roles";
import routes from "../helpers/routes";
import AssignManagerInst from "./adminOrg/AssignManagerInst";

export default function AssignRole() {
  const { user } = useAuth();

  const rolesRenderAdminOrg = [roles.adminInst];

  const rolesRenderAdmin = [roles.adminInst, roles.adminOrg];

  const rolesRenderAdminInst = [roles.teacher];

  const history = useHistory();

  const RenderForm = () => {
    switch (user.role.name) {
      /* case roles.adminInst.name:
        return <AssignManagerInst
        title='Asignar Docente a una Institución'
        rolesRender={rolesRenderAdminInst}
        /> */
      case roles.adminOrg.name:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90vw",
              height: "80vh",
            }}
          >
            <AssignManagerInst
              title="Asignar Administrador a una Institución"
              rolesRender={rolesRenderAdminOrg}
            />
          </div>
        );
      case roles.admin.name:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90vw",
              height: "80vh",
            }}
          >
            <AssignManagerInst
              title="Asignar Administradores"
              rolesRender={rolesRenderAdmin}
            />
          </div>
        );
      default:
        history.push(routes.account);
        break;
    }
    //return <AssignManagerInst />
  };

  return <RenderForm />;
}
