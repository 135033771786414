import React, { useContext, useState, useEffect } from "react";


export default function MenuAppBar() {


  return (
    <div>
      Admin
    </div>
  );
}

