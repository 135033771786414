import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Grid, Transition, Divider } from "semantic-ui-react";
import CardRequestsAdm from "../components/CardRequestsAdm";
import { AuthContext } from "../context/auth";
import useAuth from "../auth/useAuth";

function GenerateLicences(props) {
  //const { user } = useContext(AuthContext);

  const { loading, data } = useQuery(FETCH_REQUESTLICENCES_QUERY);
  const { user } = useAuth();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Grid
        columns={3}
        style={{
          marginLeft: "5%",
        }}
      >
        <Grid.Row className="page-title">
          <h1>Solicitudes de licencias</h1>
        </Grid.Row>
        <Divider />

        <Grid.Row>
          {loading ? (
            <h1>Loading Licences..</h1>
          ) : (
            <Transition.Group>
              {data?.getRequestsLicences &&
                data.getRequestsLicences.map((requestsLicences) => (
                  <Grid.Column
                    key={requestsLicences.id}
                    style={{ marginBottom: 20 }}
                  >
                    <CardRequestsAdm
                      key={requestsLicences.id}
                      requestsLicences={requestsLicences}
                      user={user}
                    />
                  </Grid.Column>
                ))}
            </Transition.Group>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
}

const FETCH_REQUESTLICENCES_QUERY = gql`
  {
    getRequestsLicences {
      id
      typeLicence
      countLicences
      countReq
      length
      createdAt
      state
      institution{
        name
      }
    }
  }
`;

export default GenerateLicences;
