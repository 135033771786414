import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext, useState, useEffect } from "react";
import { CircleMarker, Popup, Tooltip } from "react-leaflet";
import { Header } from "semantic-ui-react";
import Map from "../components/map/react-leaflet";

export default function LicencesByProvinces() {
  const { loading, data, error } = useQuery(FETCH_AGG_LICENCES_BY_PROVINCES);
  const [center, setCenter] = useState({
    lat: -26.8753965086829,
    lng: -54.6516966230371,
  });
  const ZOOM_LEVEL = 4;
  if (loading) return <div>Cargando...</div>;
  //if(error) return <div>Error Internal Server!</div>

  //console.log("fetch query; ", data);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Header
        as="h3"
        block
        style={{
          borderRadius: "15px",
        }}
      >
        Licencias Activas por provincias
      </Header>
      <div
        align="center"
        style={{
          background: "white",
          /*           padding: "50px", */
          borderRadius: "10px",
          boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
          overflow: "hidden",
        }}
      >
        <Map
          center={center}
          zoomLevel={ZOOM_LEVEL}
          list={data?.getLicencesByProvince}
        />
      </div>
    </div>
  );
}

const FETCH_AGG_LICENCES_BY_PROVINCES = gql`
  query {
    getLicencesByProvince {
      province {
        id
        nombre
        centroide {
          lat
          lon
        }
      }
      total
    }
  }
`;
