import React, { useContext, useState, useEffect } from "react";
import { Button, Grid, Header, Icon, Item, Segment } from "semantic-ui-react";
import HeaderIcon from "../../components/HeaderIcon";
import FormEditAccount from "../../components/account/FormEditAccount";
import useAuth from "../../auth/useAuth";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import roles from "../../helpers/roles";
import { Paper } from "@material-ui/core";
import { List } from "@material-ui/icons";
import TableExport from "../../components/TableExport";
import routes from "../../helpers/routes";
import { Link } from "react-router-dom";
import { GetRoleHook } from "../../hooks/getRole";

export default function MyAccount() {
  const { user } = useAuth();
  const role = GetRoleHook();
  const { loading, data, error } = useQuery(GET_USER, {
    variables: { userId: user.id },
  });

  const Item = ({ icon, text, align, color }) => {
    return (
      <div align={align}>
        <span>
          <Icon name={icon} color="black" />
          <span style={{ color: "black" }}>{text}</span>
        </span>
      </div>
    );
  };

  const columns = [
    {
      title: "Clave",
      field: "key",
    },
    {
      title: "Valido Hasta",
      field: "dateExp",
      render: (rowData) => {
        //console.log('DATE_EXP PASADA PARA VERIFICAR', rowData);
        //const isExpired = getState(rowData.dateExp);
        return <span>{rowData.dateExp}</span>;
      },
    },
    { title: "Tipo de Licencia", field: "typeLicence" },
    {
      title: "Estado",
      field: "state",
      render: (rowData) => {
        //console.log(rowData);
        const isExpired = getState(rowData.dateExp);

        console.log("Vencida? ", isExpired);
        if (isExpired) {
          return <span style={{ color: "red" }}>Vencida</span>;
        } else {
          return <span style={{ color: "green" }}>Activa</span>;
        }
      },
    },
  ];

  const getState = (dateExp) => {
    const date = new Date();
    const dateExpLocal = new Date(dateExp);

    var dateCurrent = moment(date).format("YYYY-MM-DD");
    var dateExp = moment(dateExpLocal).format("YYYY-MM-DD");
    //const dateExpLocal = new Date(rowData.dateExp);
    //const isExp = moment(dateCurrent).isAfter(dateExp, 'day'); // true
    if (dateCurrent > dateExp) {
      //console.log(`${dateCurrent} es mayor que ${dateExp}`);
      return true;
    } else {
      //console.log(`AT ${dateCurrent} es menor que ${dateExp}`);
      return false;
    }
  };

  const options = {};

  /* let columnsRender = 1;
    //console.log('rollllll. ', roles.adminInst.name)
    if (roles.adminInst.name == user.role.name) {
        columnsRender = 2;
    } */

  if (loading) return <div>Cargando ...</div>;
  if (error) return <div>Error</div>;

  const RoleComponent = () => {
    if (role != "teacher" && role != "student") {
      return (
        <Segment>
          <Item
            icon="eye"
            text={"Iniciado como: " + roles[data.getUser.role.name].nombre}
          />
          {data?.getUser.role.institutions[0] && (
            <Item
              icon="eye"
              text={"Institution: " + data?.getUser.role.institutions[0].name}
            />
          )}
        </Segment>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div
      style={{
        //background: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80vw",
        height: "80vh",
      }}
    >
      {data && (
        <div
          style={{
            marginTop: "2em",
            paddingTop: "10px",
            paddingRight: "120px",
            paddingLeft: "15px",
            paddingBottom: "20px",
            borderRadius: 8,
            boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
            background: "rgba( 255, 255, 255, 0.7 )",
            border: "2px solid rgba( 255, 255, 255, 0.18 ),",
          }}
        >
          <div
            style={{
              marginLeft: "100px",
            }}
          >
            <HeaderIcon
              primary={data.getUser.username + " " + data.getUser.lastname}
              secondary={data.getUser.email}
              icon="user circle"
              as="h2"
            />
          </div>

          <Grid columns={2} divided>
            <Grid.Row stretched>
              <Grid.Column>
                <Segment>
                  <Grid.Column>
                    <Segment>
                      <Item
                        icon="mail"
                        text={"Correo Electrónico: " + data.getUser.email}
                      />
                    </Segment>
                    <Segment>
                      <Item
                        icon="caret right"
                        text={"Nombre: " + data.getUser.username}
                      />
                    </Segment>
                    <Segment>
                      <Item
                        icon="caret right"
                        text={"Apellido: " + data.getUser.lastname}
                      />
                    </Segment>
                    <Segment>
                      <Item
                        icon="calendar alternate outline"
                        text={
                          "Registro: " +
                          moment(data.getUser.createdAt).format("L")
                        }
                      />
                    </Segment>
                    <RoleComponent />

                    {/* <Button
                      style={{
                        color: "whitesmoke",
                        backgroundColor: "#347ebf",
                      }}
                      fluid
                    >
                      Modificar Datos
                    </Button> */}
                    <Link
                      to={{
                        pathname: routes.changePassword,
                        /* state: params.row.id, // your data array of objects */
                      }}
                    >
                      <Button
                        style={{
                          color: "whitesmoke",
                          backgroundColor: "#347ebf",
                        }}
                        fluid
                      >
                        Cambiar Contraseña
                      </Button>
                    </Link>
                  </Grid.Column>
                </Segment>
              </Grid.Column>

              {/* {roles.adminInst.name == user.role.name && */}
              <Grid.Column>
                <div
                  style={{
                    color: "whitesmoke",
                    borderRadius: "5px",
                    lineHeight: "30px",
                    width: "550px",
                    height: "30px",
                    backgroundColor: "#347ebf",
                    marginBottom: "1em",
                    boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
                    background: "rgba( 255, 255, 255, 0.7 )",
                    border: "2px solid rgba( 255, 255, 255, 0.18 ),",
                  }}
                >
                  <Item icon="key" text={"Licencias"} align="center" />
                </div>
                <Paper
                  style={{
                    borderRadius: "5px",
                    width: "550px",
                    maxHeight: " 400",
                    overflow: "auto",
                  }}
                >
                  {/* {data.getUser.licences.map((lic, i) => {
                                        return (
                                            <Segment>
                                                <Item icon='angle right' text={i+1 + ': ' + 'Iniciado como: asd'} />
                                            </Segment>
                                        )
                                    })} */}
                  <TableExport
                    options={options}
                    columns={columns}
                    data={data.getUser.licences}
                    title={""}
                  />
                </Paper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <FormEditAccount />
        </div>
      )}
    </div>
  );
}
const GET_USER = gql`
  query getUser($userId: ID!) {
    getUser(_id: $userId) {
      id
      email
      username
      lastname
      createdAt
      licences {
        id
        key
        dateExp
        typeLicence
      }
      role {
        name
        institutions {
          id
          name
        }
      }
    }
  }
`;
