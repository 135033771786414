import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { AuthContext } from "../context/auth";
import Button from "@material-ui/core/Button";
import { BrowserRouter, Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Image } from "semantic-ui-react";

//import Drawer from "./Drawer";

import { useMutation } from "@apollo/react-hooks";

import { SiGoogletagmanager } from "react-icons/si";

import GroupWorkIcon from "@material-ui/icons/GroupWork";
import useAuth from "../auth/useAuth";
import routes from "../helpers/routes";
import gql from "graphql-tag";
import Swal from "sweetalert2";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    float: "left",
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // session manager
  const pathname = window.location.pathname;
  const path = pathname === "/" ? "home" : pathname.substr(1);
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  //const { user, logout } = useContext(AuthContext);
  const { isLogged, logout, user } = useAuth();
  const openLogin = Boolean(anchorEl);

  const handleLogout = () => {
    //console.log("Deberia cerrar sesion.!");
    // call mutation! ...
    handleClose();
    logout();
    //logoutMut();
  };

  const handleAccount = () => {
    handleClose();
    history.push(routes.account);
  };

  //console.log("user", user);

  /* const [logoutMut, { loading }] = useMutation(LOGOUT_MUTATION, {
    update(_, { data }) {
      handleClose();
      logout();
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log("el error :: ", err);
      logout();
    },
  }); */

  return (
    <div className={classes.root}>
      {/* <FormGroup>
        <FormControlLabel
          control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <AppBar position="relative" style={{ background: "#363636" }}>
        <Toolbar className={classes.navbar}>
          <Image size="small" src="/images/FANLAB-Logo.svg" className="logo" />

          {isLogged() && (
            <div>
              <span>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <span style={{ fontSize: "1rem" }}>{user.email}</span>
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openLogin}
                  onClose={handleClose}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                  <MenuItem onClick={handleAccount}>Mis Datos</MenuItem>
                  <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                </Menu>
              </span>
              {/* :
                <span>
                  <Link to={routes.login}>
                  <Button color="white" >Iniciar Sesión</Button>
                  </Link>
                  <Link to={routes.register}>
                  <Button color="white">Registrarme</Button>
                  </Link>
                </span> */}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;
