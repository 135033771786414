import { createContext, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";

import jwtDecode from "jwt-decode";
import routes from "../helpers/routes";

export const AuthContext = createContext();

const initialState = {
  user: null,
};
try {
  if (localStorage.getItem("jwtToken")) {
    const decodedToken = jwtDecode(localStorage.getItem("jwtToken"));

    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("jwtToken");
    } else {
      initialState.user = decodedToken;
    }
  }
} catch (error) {
  console.log(error);
}

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

export default function AuthProvider({ children }) {
  //const [user, setUser] = useState(null);
  const [state, dispatch] = useReducer(authReducer, initialState);

  //const [user, setUser] = useState(null);
  //const [user, setUser] = useState(null);
  const history = useHistory();

  // login and logout
  const login = (userCredentials, fromlocation) => {
    //setUser(userCredentials);
    console.log('credentials;:;;: ', userCredentials)
    //debugger;
    localStorage.setItem("jwtToken", userCredentials.token);

    dispatch({
      type: "LOGIN",
      payload: userCredentials,
    });
    //console.log("location ds", fromlocation.pathname);
    //debugger;
    // redirect to account
    if (fromlocation.pathname == routes.home) {
      window.location.href = routes.account;
    } else if (fromlocation) window.location.href = fromlocation.pathname;
    //if (fromlocation) history.push(fromlocation);
  };
  const logout = () => {
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT" });
    //setUser(null);
  };

  //const getRole = () => user.role;

  const isLogged = () => !!state.user;
  const hasRole = (role) => state.user?.role?.name === role;
  const getRole = () => state.user?.role?.name;
  const getLicences = () => state.user?.licences;

  const contextValue = {
    user: state.user,
    isLogged,
    hasRole,
    login,
    logout,
    getRole,
    getLicences
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
