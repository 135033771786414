import { Button, Form } from "semantic-ui-react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useForm } from "../../util/hooks";
import Swal from "sweetalert2";
import Select from "../../components/Select";
import { useEffect, useState } from "react";
import useAuth from "../../auth/useAuth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#347ebf",
      },
    },
  },
});

function PostForm() {
  const classes = useStyles();

  const [institutionSelected, setInstitutionSelected] = useState(false);
  const [keySelect, setKeySelect] = useState(1);

  const { user } = useAuth();
  const {
    loading: loadingInst,
    error: errorFetchInst,
    data: institutionsFecth,
  } = useQuery(FETCH_USER_INSTITUTION_QUERY, {
    variables: { id: user.id },
  });

  const { values, onChange, onSubmit } = useForm(createCourseCallback, {
    name: "",
  });

  useEffect(()=> {
    console.log('institutions fetch: ', institutionsFecth)
  }, [institutionsFecth])

  const [createCourse, { error }] = useMutation(CREATE_COURSE_MUTATION, {
    variables: {
      institutionId: institutionSelected?.id,
      course: {
        name: values.name,
        managerId: user.id,
      },
    },
    update(proxy, result) {
      Swal.fire({
        title: "Success",
        text: "Curso creado correctamente!",
        icon: "success",
      });
      values.name = "";
      setKeySelect(keySelect + 1);
      setInstitutionSelected(false);
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      //console.log(err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
    },
  });

  function createCourseCallback() {
    if (!values.name || !institutionSelected.id) {
      //console.log();
      Swal.fire({
        title: "Error",
        text: "Por favor rellenar todos los campos requeridos!",
        icon: "error",
      });
    } else {
      createCourse();
      /* console.log(
        "completo todos los campos",
        values.name,
        institutionSelected.id
      ); */
    }
  }

  const onChangeInst = (event, newValue) => {
    //console.log("selected INST: ", newValue);
    setInstitutionSelected(newValue);
    //props.selected && props.selected({ id: newValue?.id, name: newValue?.name});
  };

  const getOptionInstitution = (option) => option.name;

  // fetch institution list
  if (loadingInst) return "Loading...";
  //if (errorFetchInst) return `Error! ${errorFetchInst.graphQLErrors[0]?.message}`;

  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Form
        onSubmit={onSubmit}
        style={{
          minWidth: "500px",
          minHeight: "250px",
          borderRadius: "10px",
          padding: "30px",
          boxShadow: "4px 0px 10px -8px rgba(0,0,0,0.96)",
          background: "rgba( 255, 255, 255, 0.8 )",
        }}
      >
        <h1>Crear Curso.</h1>
        <Form.Field className={classes.select}>
          <Form.Input
            placeholder="Nombre"
            name="name"
            onChange={onChange}
            value={values.name}
            error={error ? true : false}
            required
          />
          <Form.Field>
            <Select
              key={keySelect}
              getOptionLabel={getOptionInstitution}
              onChange={onChangeInst}
              label="Mis Instituciones"
              loading={loadingInst}
              list={institutionsFecth?.getUserInstitutions ? institutionsFecth?.getUserInstitutions : []}
            />
          </Form.Field>

          <Button
            type="submit"
            style={{ background: "#347EBF", color: "white" }}
          >
            Crear
          </Button>
        </Form.Field>
      </Form>
      {error && (
        <div className="ui error message" style={{ marginBottom: 20 }}>
          <ul className="list">
            <li>{error.message}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

const CREATE_COURSE_MUTATION = gql`
  mutation assignInstitutionCourse($institutionId: ID!, $course: CourseInput!) {
    assignInstitutionCourse(institutionId: $institutionId, course: $course) {
      id
      name
    }
  }
`;

const FETCH_INSTITUTION_QUERY = gql`
  query ($id: ID!) {
    getUser(_id: $id) {
      licences {
        institution {
          id
          name
          address
        }
      }
    }
  }
`;

const FETCH_USER_INSTITUTION_QUERY = gql`
  query ($id: ID!) {
    getUserInstitutions(id: $id) {
      id
      name
      address
    }
  }
`;

/* const FETCH_INSTITUTION_QUERY = gql`
  query ($id: ID!) {
    getUser(_id: $id) {
      licences {
        idInstitution
        nameInstitution
        addressInstitution
      }
    }
  }
`; */

export default PostForm;
