import React, { useContext, useState, useEffect } from "react";
import {
    useParams
} from "react-router-dom";
import Link from '@material-ui/core/Link';
import Swal from 'sweetalert2';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Container, Divider, Grid, Header, Icon, Segment } from "semantic-ui-react";
import routes from "../../helpers/routes";
import Button from '@material-ui/core/Button';
import Search from "@material-ui/icons/Search";

export default function MenuAppBar() {
    const { code } = useParams();
    //console.log('code:: ', code);
    const [status, setStatus] = useState({ loading: true, state: false });

    const [sendCode, { loading }] = useMutation(CONFIRM_EMAIL, {
        update(
            _,
            {
                data: { confirmEmail: statusCode }
            }
        ) {
            //console.log('User data register', statusCode);
            if (statusCode) {
                setStatus({ loading: false, state: true });
                Swal.fire({
                    title: 'Exito',
                    text: 'Correo electronico verificado correctamente!',
                    icon: 'success',
                });
                return;
            } else {
                setStatus({ loading: false, state: false });
                Swal.fire({
                    title: 'Error',
                    text: 'El codigo de verificacion no es correcto!',
                    icon: 'error',
                });
                return;
            }
            //context.login(userData);
        },
        onError(err) {
            //setErrors(err.graphQLErrors[0].extensions.exception.errors);
            //console.log('Otro error', err);
            Swal.fire({
                title: 'Error',
                text: err.graphQLErrors[0]?.message,
                icon: 'error',
            });
        },
        variables: { code: code }
    });

    useEffect(() => {
        sendCode();
    }, [])

    if (status.loading) {
        return (
            <div>
                Cargando ...
            </div>
        )
    } else {
        if (status.state) {
            return (
                <Container>

                    <div>
                        <Segment placeholder>
                            <Grid columns={1} stackable textAlign='center'>
                            <Grid.Row verticalAlign='middle'>
                                <Header as='h2' icon textAlign='center'>
                                    <Icon name='check' circular />
                                    <Header.Content>
                                        Gracias por confirmar su correo electronico!
                                    </Header.Content>
                                </Header>
                            </Grid.Row>
                                <Divider horizontal>Si eres Docente o Administrador</Divider>
                                
                                <Grid.Row verticalAlign='middle'>
                                    <Grid.Column>
                                        <Link href={routes.login} variant="body2">
                                            <Header icon>
                                                <Icon name='user circle' />
                                                Iniciar Sesión
                                            </Header>
                                        </Link>
                                    </Grid.Column>
                                </Grid.Row>
                                <Divider horizontal>Si eres Estudiante, Inicia el Simulador para continuar
                                </Divider>

                            </Grid>
                        </Segment>
                    </div>
                </Container>
            )
        } else {
            return (
                <Container>
                    <Header as='h2' icon textAlign='center'>
                        <Icon name='close' circular />
                        <Header.Content>Codigo de verificacion no valido!</Header.Content>
                    </Header>
                    <div>
                        <Segment placeholder>
                            <Grid columns={2} stackable textAlign='center'>
                                <Divider vertical>Or</Divider>

                                <Grid.Row verticalAlign='middle'>
                                    <Grid.Column>
                                        <Link href={routes.login} variant="body2">
                                            <Header icon>
                                                <Icon name='user circle' />
                                                Iniciar Sesión
                                            </Header>
                                        </Link>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Link href={routes.register} variant="body2">
                                            <Header icon>
                                                <Icon name='add user' />
                                                Registrarme
                                            </Header>
                                        </Link>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>


                    </div>
                </Container>
            )
        }
    }
}


const CONFIRM_EMAIL = gql`
  mutation confirmEmail(
    $code: String!
  ) {
    confirmEmail(
        token: $code
    )
  }
`;
