const routes = {
    home: '/',
    guest: '/guest',
    activeLicence: '/licence',
    account: '/account',
    identity: '/identity',
    changePassword: '/password',
    admin: {
        home: '/admin',
        requestLicences: '/requestLicencesAdm',
        user: (userId) => (userId ? `/user/:${userId}` : '/user/:userId'),
        assignManagerInst: '/managerInst'
    },
    adminOrg: {
        generateLicences: '/generateLicences',
        requestLicences: '/requestLicences',
        createInstitution: '/createInstitution',
        listInstitution: '/institutions',
        institution: (institutionId) => (institutionId ? `/institutions/${institutionId}` : '/institutions/:institutionId'),
        assignManagerInst: '/managerInstOrg',
        myRequests: '/requestLicencesOrg',
        licencesByProvinces: '/licencesByProvinces'
    },
    adminInst: {
        generateLicences: '/generateLicences',
        myLicences: '/myLicences',
        assignTeacher: '/assignTeacher'
    },
    teacher: {
        board: '/board',
        course: '/course',
        session: '/session',
        activity: '/activity',
        createCourse: '/course/new'
    },
    login: '/login',
    register: '/register',
    verify: (code) => (code ? `/verify/:${code}` : '/verify/:code'),
}

export default routes;