import React from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export default function MiniDrawer({
  handleDrawerClose,
  open,
  handleItemDrawerSelected,
}) {
  const classes = useStyles();
  const theme = useTheme();
  // /* const [open, setOpen] = React.useState(false); */

  /* const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  }; */
  const itemsDrawer = [
    {
      tittle: "Ver licencias",
      name: "viewLicences",
    },
    {
      tittle: "Mis Solicitudes",
      name: "myReqLicences",
    },
    {
      tittle: "Solicitar Licencias",
      name: "reqLicences",
    },
    {
      tittle: "Crear Institucion",
      name: "createInstitution",
    },
    {
      tittle: "Activar Licencia",
      name: "activeLicence",
    },
  ];

  const itemsDrawerTeacher = [
    {
      tittle: "Mis Insituciones",
      name: "teacherBoard",
    },
    {
      tittle: "Activar Licencia",
      name: "activeLicence",
    },
  ];

  const itemsDrawerAdmin = [
    {
      tittle: "Solicitudes de Licencias",
      name: "reqLicencesAdmin",
    },
  ];

  const getIcon = (name) => {
    switch (name) {
      case "activeLicence":
        return <VpnKeyIcon />;
      case "viewLicences":
        return <VpnKeyIcon />;
      default:
        return <MailIcon />;
    }
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      Admin ORG
      <List>
        {itemsDrawer.map((item, index) => (
          <ListItem
            button
            key={item.tittle}
            onClick={() => handleItemDrawerSelected(item)}
          >
            <ListItemIcon>{getIcon(item.name)}</ListItemIcon>
            <ListItemText primary={item.tittle} />
          </ListItem>
        ))}
      </List>
      <Divider />
      Docente
      <List>
        {itemsDrawerTeacher.map((item, index) => (
          <ListItem
            button
            key={item.tittle}
            onClick={() => handleItemDrawerSelected(item)}
          >
            <ListItemIcon>{getIcon(item.name)}</ListItemIcon>
            <ListItemText primary={item.tittle} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        Admin
        {itemsDrawerAdmin.map((item, index) => (
          <ListItem
            button
            key={item.tittle}
            onClick={() => handleItemDrawerSelected(item)}
          >
            <ListItemIcon>{getIcon(item.name)}</ListItemIcon>
            <ListItemText primary={item.tittle} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
