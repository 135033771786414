import { Button, Form } from "semantic-ui-react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useForm } from "../../util/hooks";
import Swal from "sweetalert2";
import useAuth from "../../auth/useAuth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#347ebf",
      },
    },
  },
});

function ChangePasswordForm() {
  const classes = useStyles();
  const { user } = useAuth();

  const { values, onChange, onSubmit } = useForm(changePasswordCallback, {
    oldPass: "",
    newPass: "",
    newPass2: "",
  });

  const [changePassword, { error }] = useMutation(CHANGE_PASSWORD, {
    variables: {
      oldPassword: values.oldPass,
      newPassword: values.newPass,
    },
    update(proxy, result) {
      values.oldPass = "";
      values.newPass = "";
      values.newPass2 = "";
      Swal.fire({
        title: "Éxito",
        text: "Contraseña modificada correctamente!",
        icon: "success",
      });
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log(err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
    },
  });

  function changePasswordCallback() {
    if (values.newPass == values.newPass2) {
      // hacer validacion de contraseña valida para seguridad ...
      //console.log("Aqui metodo changePass");
      changePassword();
    } else {
      Swal.fire({
        title: "Error",
        text: "Las nuevas contraseñas no coinciden!",
        icon: "error",
      });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Form
        onSubmit={onSubmit}
        style={{
          minWidth: "500px",
          minHeight: "250px",
          borderRadius: "10px",
          padding: "30px",
          boxShadow: "4px 0px 10px -8px rgba(0,0,0,0.96)",
          background: "rgba( 255, 255, 255, 0.8 )",
        }}
      >
        {error && (
          <div>
            <div class="ui negative message">
              <i class="close icon"></i>
              <div class="header">{error.message}</div>
              {/* <p>That offer has expired</p> */}
            </div>
          </div>
        )}
        <h1>Cambiar contraseña</h1>
        <Form.Field className={classes.select}>
          <Form.Input
            placeholder="Contraseña actual"
            name="oldPass"
            onChange={onChange}
            value={values.oldPass}
            /* error={error ? true : false} */
            type="password"
            required
          />
          <Form.Input
            placeholder="Nueva contraseña"
            name="newPass"
            onChange={onChange}
            value={values.newPass}
            /* error={error ? true : false} */
            type="password"
            required
          />
          <Form.Input
            placeholder="Repite su nueva contraseña"
            name="newPass2"
            onChange={onChange}
            value={values.newPass2}
            /* error={error ? true : false} */
            type="password"
            required
          />
          <Button
            type="submit"
            style={{ background: "#347EBF", color: "white" }}
            fluid
          >
            Crear
          </Button>
        </Form.Field>
      </Form>
    </div>
  );
}

const CHANGE_PASSWORD = gql`
  mutation changePasswordRequest($oldPassword: String!, $newPassword: String!) {
    changePasswordRequest(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export default ChangePasswordForm;
