import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Swal from "sweetalert2";
import { Route, Redirect, Link } from "react-router-dom";
import InstitutionCard from "./InstitutionCard";
import { useLazyQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

import { AuthContext } from "../context/auth";
import useAuth from "../auth/useAuth";
import { Button } from "semantic-ui-react";
import routes from "../helpers/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "grey",
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: true,
});

export default function NestedList(props) {
  const { institutions, history } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [activeIndex, setActiveIndex] = useState(-1);
  const [currentCourse, setCurrentCourse] = useState();  

  const handleClick = (i, idInstitution) => {
    //console.log("id institution: ", idInstitution);
    getInstitution({ variables: { id: idInstitution, managerId: user?.id } });
    //if (data) console.log(data);
    //const newIndex = activeIndex === i ? -1 : i;
    const newIndex = activeIndex === i ? -1 : i;
    setActiveIndex(newIndex);
  };

  const handleClickCourse = (cours) => {
    console.log("qwe", cours);

    //handleItemDrawerSelected('');
  };

  const [getInstitution, { loading, data }] = useLazyQuery(
    FETCH_INSTITUTION_QUERY
  );

  /* const handleClick = () => {
        setOpen(!open);
    }; */

  //const { user } = useContext(AuthContext);
  const { user } = useAuth();

  useEffect(() => {
    const id = user?.id;
    //console.log("data ", data);
  }, [data]);

  const handlenewCourse = (institutionId) => {
    console.log("nuevo curso para inst de id: ", institutionId);
  };

  return (
    <>
      {!user && <Redirect to={routes.login} />}
      <List
        style={{
          minWidth: "500px",
          minHeight: "250px",
          borderRadius: "10px",
          padding: "30px",
          boxShadow: "4px 0px 10px -8px rgba(0,0,0,0.96)",
          background: "rgba( 255, 255, 255, 0.8 )",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={<h1 style={{ color: "#363636" }}>Mis instituciones</h1>}
        className={classes.root}
      >
        <div
          style={{
            "white-space": "pre-line",
            "overflow-y": "scroll",
            maxHeight: 500,
          }}
        >
          {institutions &&
            institutions.map((inst, i) => (
              <div key={i}>
                <ListItem
                  button
                  onClick={() => handleClick(i, inst.id)}
                  key={i}
                >
                  <InstitutionCard
                    id={inst?.id}
                    name={inst?.name}
                    address={inst?.address}
                  />

                  {activeIndex === i ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={activeIndex === i} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* <ListItem className={classes.nested} key={-1}>
                                        <Button
                                            variant="contained"
                                            color="blue"
                                            href={routes.home}
                                            
                                        >
                                            Nuevo curso
                                        </Button>
                                    </ListItem> */}
                    {data?.getCoursesByManagerIdWeb?.courses &&
                      data.getCoursesByManagerIdWeb.courses.map((course, i) => (
                        <Link
                          key={i}
                          to={{
                            pathname: routes.teacher.course,
                            state: course, // your data array of objects
                          }}
                        >
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={() => handleClickCourse(course)}
                            key={i}
                          >
                            <ListItemIcon>
                              <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary={course.name} />
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                </Collapse>
              </div>
            ))}
        </div>
      </List>
    </>
  );
}

const FETCH_INSTITUTION_QUERY = gql`
  query ($id: ID!, $managerId: ID!) {
    getCoursesByManagerIdWeb(_id: $id, managerId: $managerId) {
      courses {
        _id
        name
        managerId
        roomSessions
      }
    }
  }
`;
