import React from "react";
import {
  Button,
  Card,
  Icon,
  Label,
  Modal,
  Form,
  Header,
  Divider,
} from "semantic-ui-react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useForm } from "../util/hooks";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

import TableExport from "./TableExport";
import Swal from "sweetalert2";
import moment from "moment";
import { getPdfLicences } from "../hooks/getPdfLicences";
function CardRequestLicences({
  requestsLicences: {
    id,
    typeLicence,
    countLicences,
    countReq,
    length,
    createdAt,
    state,
    institution,
  },
}) {
  const { values, onChange } = useForm(createLicencesCallback, {
    quantity: "",
  });

  const [createLicences, { error }] = useMutation(CREATE_LICENCES, {
    variables: { requestLicenceId: id, count: parseInt(values.quantity) },
  });

  function createLicencesCallback() {
    //console.log("values quaantity: ", values);
    if (values.quantity != "") {
      //createLicences();
    }
  }

  const [open, setOpen] = React.useState(false);

  const submitLicence = () => {
    if (values.quantity < 1) {
      return;
    }
    createLicences()
      .then((resp) => {
        throwAlertSucces(resp.data.assignLicencesFromRequest);
        //console.log(resp);
        setOpen(false);
      })
      .catch((e) => {
        /*
      console.log(e);
      console.log();
      console.log(e.errors);
      debugger;
      */
        //alert(e.graphQLErrors[0].message);
        toast(
          {
            title: "ERROR",
            description: <p>{e.graphQLErrors[0].message}</p>,
            type: "warning",
            icon: "envelope",
            animation: "bounce",
            color: "red",
            icon: "x",
          },
          () => console.log("toast closed"),
          () => console.log("toast clicked"),
          () => console.log("toast dismissed")
        );
      });
    /*
    createLicences();
    setOpen(false);
    */
  };

  const throwAlertSucces = (licences) => {
    Swal.fire({
      title: "Exito",
      icon: "success",
      html: "Licencias Generadas correctamente!",
      showCancelButton: true,
      confirmButtonText: `Descargar Licencias`,
      cancelButtonText: `Confirmar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      //console.log(result);
      if (result.isConfirmed) {
        // Metodo de descarga de licencias ... 
        getPdfLicences(licences);
        //console.log('Collection: ', resp)
        Swal.fire({
          title: "Descarga Iniciada...",
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
      }
    });
  };
  return (
    <Card>
      <SemanticToastContainer />
      <Label as="a" width="%100">
        Periodo de validéz: {length} meses
      </Label>
      <Card.Content>
        <Card.Header align="center">{institution.name}</Card.Header>
        <Divider />
        <Card.Header>{typeLicence}</Card.Header>
        <Card.Meta>
          Solicitado: {moment(createdAt).format("YYYY-MM-DD")}
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          {state == "accepted" ? (
            <Button basic color="teal">
              Aceptada
            </Button>
          ) : (
            <Button basic color="red">
              Solicitada
            </Button>
          )}
        </div>
      </Card.Content>
      <Card.Content extra>
        <a>
          {" "}
          {countLicences} / {countReq} Disponibles
        </a>
      </Card.Content>
      <div className="ui two buttons">
        {state == "accepted" ? (
          <Modal
            as={Form}
            trigger={<Button disabled={countLicences < 1}>Generar</Button>}
            size="small"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            <Header content="Generar Licencias" />
            <Modal.Content>
              <div>
                <h4>Válido por {length} meses.</h4>
                <h4>Tipo de licencia: {typeLicence}</h4>
              </div>
              <br></br>
              <Form.Input
                type="number"
                required
                min={1}
                max={countLicences}
                fluid
                name="quantity"
                label="Cantidad"
                placeholder="Cantidad..."
                onChange={onChange}
                value={values.quantity}
              />
            </Modal.Content>
            <Modal.Actions>
              <label align="left">Cantidad disponible: {countLicences} </label>
              <Button basic color="red" onClick={() => setOpen(false)}>
                Cancelar
              </Button>
              <Button color="green" onClick={() => submitLicence()}>
                Confirmar
              </Button>
            </Modal.Actions>
          </Modal>
        ) : (
          <Button disabled>Generar</Button>
        )}
      </div>
    </Card>
  );
}

const CREATE_LICENCES = gql`
  mutation assignLicencesFromRequest($requestLicenceId: ID!, $count: Int!) {
    assignLicencesFromRequest(
      requestLicenceId: $requestLicenceId
      count: $count
    ) {
      key
      length
      typeLicence
      dateExp
    }
  }
`;

export default CardRequestLicences;
