import MenuBar from "../MenuBar";
import useAuth from "../../auth/useAuth";

import React, { useState } from "react";
import MakeshiftDrawer from "./MakeshiftDrawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Container, Hidden } from "@material-ui/core";
import FooterHome from "./FooterHome";
import { createContext, useContext } from "react";
import { AuthContext } from "./../../auth/AuthProvider";
import imgBackground from "./../../util/imgs/1.png";
import { pink } from "@material-ui/core/colors";

const botGridStyle = {
  minHeight: "100px",
  backgroundColor: "blue",
  marginBottom: "10px",
};
export default function Layout({ children }) {
  const [isOpen, setIsOpen] = useState(true);

  // para verificar login y mostrar barra de herramientas
  const { isLogged } = useAuth();
  const { user } = useContext(AuthContext);
  console.log("login: ", isLogged);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid container direction="row">
          <MenuBar />
        </Grid>
        <Grid container direction="row" style={{}}>
          <span
            style={{
              display: "inline-flex",
              justifyContent: "center",
              width: "100%",
              zIndex: "0",
              flexDirection: "column",
            }}
          >
            {isLogged() && (
              <Grid item>
                {/* <Button variant="contained" color="primary" onClick={toggle}>MENU</Button> */}
                <div className="divBosqueBlur"></div>
                
                <div
                  style={{
                    display: "flex",
                    minHeight: "40vw",
                  }}
                >
                  <MakeshiftDrawer open={isOpen} />
                  {children}
                </div>
              </Grid>
            )}
            {user === null ? children : console.log("nada")}
          </span>
        </Grid>
        <div className="divBlur"></div>
        <div style={{ bottom: 0, width: "100%" }}>
          <FooterHome />
        </div>
        {/* <Grid container direction="row" style={botGridStyle}>
				FOOTER
			</Grid> */}
      </div>
    </>

    /* 		<div style={{ position: 'relative', }}>
				<MenuBar />
				{children}
				<h5>footer</h5>
			</div> */
  );
}
