import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { useMutation } from "@apollo/react-hooks";
//import { AuthContext } from '../context/auth';
import { useForm } from "../../util/hooks";
import Swal from "sweetalert2";
import Select from "../../components/Select";
import roles from "../../helpers/roles";
import SelectAsync from "../../components/SelectAsync";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router";
import useAuth from "../../auth/useAuth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#347ebf",
      },
    },
  },
});

function AssignManagerInst({ title, rolesRender }) {
  const classes = useStyles();
  //const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [typeSelected, setTypeSelected] = useState(false);
  const [institutionSelected, setInstitutionSelected] = useState(false);
  const [userSelected, setUserSelected] = useState(false);

  const history = useHistory();
  const {
    loading: loadingInst,
    error,
    data: institutionsFecth,
  } = useQuery(FETCH_INSTITUTION_QUERY);
  const {
    loading: loadingUsers,
    error: errorUsers,
    data: usersFecth,
  } = useQuery(FETCH_USER_QUERY);

  const { user } = useAuth();

  const [rolSelected, setRolSelected] = useState(false);
  const [keyRolSelected, setKeyRolSelected] = useState(1);
  const [keyUserSelected, setKeyUserSelected] = useState(1);
  const [keyInstSelected, setKeyInstSelected] = useState(1);

  const rolesRenderAdminOrg = [roles.adminInst];

  const rolesRenderAdmin = [roles.adminInst, roles.adminOrg, roles.teacher];

  const { onChange, onSubmit, values } = useForm(assignManager, {
    countLicences: "",
    length: "",
  });

  const [assignManagerMutation, { loading }] = useMutation(
    ASSIGN_MANAGER_INST,
    {
      update(_, { data: { assignManagerInst: userDataAssigned } }) {
        //context.login(userData);
        //console.log("User data assinged: ", userDataAssigned);
        Swal.fire({
          title: "Éxito",
          text: "Rol asignado correctamente!",
          icon: "success",
        });

        clearValues();

        //history.push(routes.adminOrg.assignManagerInst);
        //context.login(userData);
        //history.push('/');
      },
      onError(err) {
        //console.log(err.graphQLErrors[0]?.message);
        //debugger;
        //setErrors(err.graphQLErrors[0]?.extensions?.exception?.errors);
        Swal.fire({
          title: "Error",
          text: err.graphQLErrors[0]?.message,
          icon: "error",
        });
      },
      variables: {
        institutionId: institutionSelected.id,
        roleName: rolSelected.name,
        userId: userSelected.id,
      },
    }
  );

  function assignManager() {
    //console.log("DATA FORM:; ", userSelected, institutionSelected, rolSelected);

    if (
      !rolSelected?.id ||
      !institutionSelected?.id ||
      !userSelected?.id
    ) {
      //console.log();
      Swal.fire({
        title: "Error",
        text: "Por favor rellenar todos los campos requeridos!",
        icon: "error",
      });
    } else {
      assignManagerMutation();
    }

  }

  // fetch institution list
  if (loadingInst) return "Loading...";
  if (error) return `Error! ${error?.message}`;

  const onChangeUser = (event, newValue) => {
    //console.log("selected USER: ", newValue);
    setUserSelected(newValue);
    //props.selected && props.selected({ id: newValue?.id, name: newValue?.name});
  };

  const onChangeInst = (event, newValue) => {
    //console.log("selected INST: ", newValue);
    setInstitutionSelected(newValue);
    //props.selected && props.selected({ id: newValue?.id, name: newValue?.name});
  };
  const onChangeRole = (event, newValue) => {
    //console.log("selected role: ", newValue);
    setRolSelected({
      id: newValue?.id,
      name: newValue?.name ? newValue?.name : newValue?.nombre,
    });
  };

  /* const onChangeInst = (event, newValue) => {
    console.log('selected role: ', newValue);
    setRolSelected({ id: newValue?.id, name: newValue?.name ? newValue?.name : newValue?.nombre });
  } */
  //console.log(user)

  const clearValues = () => {
    setKeyRolSelected(keyRolSelected + 1);
    setRolSelected(false);
    setKeyUserSelected(keyUserSelected + 1);
    setInstitutionSelected(false);
    setKeyInstSelected(keyInstSelected + 1);
    setUserSelected(false);
  };
  return (
    <div className="form-container">
      <Form
        onSubmit={onSubmit}
        noValidate
        className={loading ? "loading" : ""}
        style={{
          padding: "50px",
          borderRadius: "10px",
          boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",

          background: "rgba( 255, 255, 255, 0.7 )",
        }}
      >
        <h1>{title}</h1>

        <Form.Field className={classes.select}>
          <SelectAsync
            key={keyUserSelected}
            onChange={onChangeUser}
            label="Usuario"
            loading={loadingUsers}
            options={usersFecth?.getUsers}
          />
        </Form.Field>

        <Form.Field className={classes.select}>
          <Select
            key={keyRolSelected}
            list={rolesRender}
            label="Rol"
            onChange={onChangeRole}
            width={400}
          />
        </Form.Field>

        {/* {rolSelected?.name == roles.teacher.name || */}
        {rolSelected?.name == roles.adminInst.name && (
          /* En estos dos casos deberia asignarse a una institucion*/
          <Form.Field>
            <SelectAsync
              key={keyInstSelected}
              onChange={onChangeInst}
              label="Institución"
              loading={loadingInst}
              options={institutionsFecth?.getInstitutions}
            />
          </Form.Field>
        )}

        <Button type="submit" primary>
          Asignar
        </Button>
      </Form>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

const ASSIGN_MANAGER_INST = gql`
  mutation assignManagerInst(
    $userId: ID!
    $institutionId: ID
    $roleName: String!
  ) {
    assignManagerInst(
      userId: $userId
      institutionId: $institutionId
      roleName: $roleName
    ) {
      id
      email
      role {
        name
      }
    }
  }
`;

const FETCH_INSTITUTION_QUERY = gql`
  query getInstitutions {
    getInstitutions {
      id
      name
    }
  }
`;

const FETCH_USER_QUERY = gql`
  query getUsers {
    getUsers {
      id
      email
    }
  }
`;

export default AssignManagerInst;
