import React, { useContext, useState, useEffect } from "react";
import { Grid, Segment } from "semantic-ui-react";
import HeaderExampleSettingsIcon from "../components/HeaderIcon";
import { makeStyles } from "@material-ui/core/styles";
import {TextField, Typography } from "@material-ui/core";
import routes from "../helpers/routes";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useHistory } from "react-router";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "../util/hooks";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backdropFilter: "blur(5px)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 5, 2),
  },
  select: {
   
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: '#347ebf',
      }
    }
  }
}));

export default function ChangePasswordMail() {
  const history = useHistory();

  const classes = useStyles();

  const handleCancel = () => {
    history.push(routes.login);
  };

  const handleSearch = () => {
    //CheckEmail('augusto_nog@outlook.es');
  };

  const { onChange, onSubmit, values } = useForm(checkEmailCallback, {
    email: "",
  });

  function checkEmailCallback() {
    //console.log(values);
    checkEmail();
  }

  const [checkEmail, { loading }] = useMutation(CHECK_EMAIL, {
    update(_, { data }) {
      //console.log(userData);
      //context.login(userData);
      //console.log('User data login', userData);
      //login(userData, location.state?.from);
      //props.history.push('/');
      //console.log("respuesta exitosa! ", data.changePassword);
      if (data.changePassword) {
        Swal.fire({
          title: "Exito!",
          text: "Se te envio un correo electronico con tu nueva contraseña!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Iniciar Sesion!",
        }).then((result) => {
          if (result.isConfirmed) {
            /* Swal.fire(
                          'Deleted!',
                          'Your file has been deleted.',
                          'success'
                        ) */
            history.push(routes.login);
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Correo Electronico no Válido!",
          icon: "error",
        });
      }
      //history.push('/');
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log("el error :: ", err.message);
      console.log("error de graphql :: ", err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
    },
    variables: values,
  });

  if (loading) return <p>Loading ...</p>;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "80vh",
      }}
    >
      <Grid columns={1} divided>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment
              style={{
                boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
                background: "rgba( 255, 255, 255, 0.8 )",
                border: "2px solid rgba( 255, 255, 255, 0.18 )",
              }}
            >
              <HeaderExampleSettingsIcon
                primary="Restablece su contraseña"
                icon="unlock alternate"
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column>
            <Segment
              style={{
                boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
                background: "rgba( 255, 255, 255, 0.8 )",
                border: "2px solid rgba( 255, 255, 255, 0.18 )",
              }}
            >
              <form onSubmit={onSubmit}>
                <Typography variant="h5">
                  Ingresa tu correo electrónico para enviar su nueva contraseña
                  por correo.
                </Typography>
                <TextField className={classes.select}
                style={{background: 'white'}}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={onChange}
                />

                {/* <Link href={routes.register} variant="body2">
                                    {"¿Ya no tienes Acceso?"}
                                </Link> */}

                <ButtonGroup disableElevation variant="contained">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Enviar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </ButtonGroup>
              </form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

const CHECK_EMAIL = gql`
  mutation changePassword($email: String!) {
    changePassword(email: $email)
  }
`;
