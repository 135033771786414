import React, { useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AuthContext } from "../context/auth";
import Swal from "sweetalert2";

import { useForm } from "../util/hooks";

import { useHistory } from "react-router-dom";
import useAuth from "../auth/useAuth";
import routes from "../helpers/routes";
import { blue } from "@material-ui/core/colors";
import BorderColor from "@material-ui/icons/BorderColor";
import { TextFormatSharp } from "@material-ui/icons";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.faniot.com.ar">
        Fanlab
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "600px",
    width: "500px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",

    padding: "20px ",
    borderRadius: 8,
    boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
    background: "rgba( 255, 255, 255, 0.8 )",
    border: "2px solid rgba( 255, 255, 255, 0.18 ),",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#347EBF",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#347EBF",
    "&:hover": {
      background: "#347EBF",
    },
  },
  // root: {

  //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //     borderColor: "#347EBF"
  //   },

  // }
}));

export default function SignUp(props) {
  const classes = useStyles();

  //const context = useContext(AuthContext);

  const { login } = useAuth();

  //const history = useHistory();
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(registerUser, {
    username: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [addUser, { loading }] = useMutation(REGISTER_USER, {
    update(_, { data: { register: userData } }) {
      console.log("User data register", userData);

      Swal.fire({
        title: "Por favor revisa tu correo electrónico",
        text:
          "Se ha enviado un correo electronico a tu cuenta: " +
          values.email +
          " confirma que es de tu propiedad",
        icon: "warning",
      });

      window.href = routes.login;
      //context.login(userData);
      //login(userData);
      //props.history.push('/');
      //context.login(userData);
      //history.push('/');
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      //console.log('Otro error', err);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
    },
    variables: values,
  });

  function registerUser() {
    if (values.username === "asd") {
      errors.username = "Nombre requerido";
      console.log("falta nombre");
    }
    if (values.lastname === "") {
      errors.lastname = "Apellido requerido";
      console.log("falta lastna,me");
    }
    if (values.email === "") {
      errors.email = "Email requerido";
      console.log("falta email");
    }
    if (values.password === "") {
      errors.password = "Contraseña requerida";
      console.log("falta pass");
    }
    if (values.confirmPassword === "") {
      errors.confirmPassword = "Confirmacion de contraseña requerida";
      console.log("falta confirm");
    }
    //console.log(values);
    /* if (errors) {
      Swal.fire({
        title: "Alerta",
        text: 'Error de entrada' + errors,
        icon: "warning",
      });
    } else {
      addUser();
    } */
    addUser();

  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrarse
        </Typography>
        <form onSubmit={onSubmit} className={classes.form}>
          <Grid
            item
            xs={12}
            sm={20}
            style={{
              marginBottom: "20px",
            }}
          >
            <TextField
              style={{
                backgroundColor: "white",
              }}
              className={classes.root}
              autoComplete="fname"
              name="username"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="Nombres"
              autoFocus
              errors={errors.username}
              onChange={onChange}
              required
            />
          </Grid>
          <div>{errors.username && <p>Por favor ingrese un nombre válido!</p>}</div>
          <Grid
            item
            xs={12}
            sm={20}
            style={{
              marginBottom: "20px",
            }}
          >
            <TextField
              style={{
                backgroundColor: "white",
              }}
              variant="outlined"
              required
              fullWidth
              id="lastname"
              label="Apellido"
              name="lastname"
              autoComplete="lname"
              onChange={onChange}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "20px",
            }}
          >
            <TextField
              style={{
                backgroundColor: "white",
              }}
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              onChange={onChange}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "20px",
            }}
          >
            <TextField
              style={{
                backgroundColor: "white",
              }}
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{
                backgroundColor: "white",
              }}
              variant="outlined"
              className={classes.field}
              required
              fullWidth
              name="confirmPassword"
              label="Confirmar Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrarme
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href={routes.login} variant="body2">
                Ya tienes una cuenta? Inicia Sesión
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

const REGISTER_USER = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $lastname: String!
  ) {
    register(
      registerInput: {
        username: $username
        lastname: $lastname
        email: $email
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      id
      email
      password
      createdAt
      token
    }
  }
`;
