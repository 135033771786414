import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

const HeaderExampleSettingsIcon = ({ primary, secondary, icon, as }) => (
  <Header as={as} icon textAlign='center'>
    <Icon name={icon} />
    {primary}
    <Header.Subheader>
      {secondary}
    </Header.Subheader>
  </Header>
)

export default HeaderExampleSettingsIcon