const roles = {
    admin: {
        id: 1,
        name: 'admin',
        nombre: 'ADMINISTRADOR'
    },
    adminOrg: {
        id: 2,
        name: 'adminOrg',
        nombre: 'ADMINISTRADOR DE ORGANISMO'
    },
    teacher: {
        id: 3,
        name: 'teacher',
        nombre: 'DOCENTE'
    },
    guest: {
        id: '4',
        name: 'guest',
        nombre: 'INVITADO'
    },
    adminInst: {
        id: '5',
        name: 'adminInst',
        nombre: 'ADMINISTRADOR DE INSTITUCION'
    },
    student: {
        id: '6',
        name: 'student',
        nombre: 'ESTUDIANTE'
    } 
}
/* 
const roles = {
    admin: 'admin',
    adminOrg: 'adminOrg',
    teacher: 'teacher',
    guest: 'guest',
    adminInst: 'adminInst'
} */

export default roles;