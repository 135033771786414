const types = [
    {
        id: 1,
        name: 'DOCENTE',
        nombre: 'DOCENTE'
    },
    {
        id: 2,
        name: 'ESTUDIANTE',
        nombre: 'ESTUDIANTE'
    }
];

export default types;