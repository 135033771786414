import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import routes from "../../helpers/routes";
export default function MenuAppBar() {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 220,
    },
    {
      field: "address",
      headerName: "Dirección",
      width: 300,
    },
    {
      field: "location",
      headerName: "Localizacion",
      width: 300,
      renderCell: (params) => {
        //console.log('ppp', params.row.location.province)
        return (
          <span>
            {params.row.location.province.nombre},{" "}
            {params.row.location.city.nombre}
          </span>
        );
      },
    },
    {
      field: "Accion",
      sortable: false,
      width: 250,
      headerAlign: "center",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Link
              to={{
                pathname: routes.adminOrg.institution(params.row.id),
                /* state: params.row.id, // your data array of objects */
              }}
            >
              <Button
                variant="contained"
                color="blue"
                onClick={(event) => {
                  handleClickEdit(event, params);
                }}
              >
                EDITAR
              </Button>
            </Link>
            {/* <Button
              variant="contained"
              color="red"
              onClick={(event) => {
                handleClickDelete(event, params);
              }}
            >
              ELIMINAR
            </Button> */}
          </span>
        );
      },
    },
  ];

  const handleClickDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "¿Esta seguro que desea esta Institución?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, aceptar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          /*                 acceptReqLicences().then((resp) => {
                                    console.log(resp);
                                    swalWithBootstrapButtons.fire(
                                        'Exito!',
                                        'La Solicitud de licencia ha sido aceptada correctamente!',
                                        'success'
                                      )
                                }).catch(e => {
                                    console.log(e);
                                    swalWithBootstrapButtons.fire(
                                        'Ops!',
                                        `${e.graphQLErrors[0].message}`,
                                        'error'
                                      )
                                }); */
          console.log("aceptado!");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", "", "warning");
        }
      });
  };

  const handleClickEdit = () => {
    console.log("edit");
  };

  const { loading, data } = useQuery(FETCH_INSTITUTIONS, {
    variables: {},
  });

  /* useEffect(() => {
    console.log("DATA RECEIVED: ", data);
  }, [data]); */

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      
      }}
    >
      {loading ? (
        "Cargando ..."
      ) : (
        <div >
          <Table rowsData={data.getInstitutions} columnsData={columns} />
        </div>
      )}
    </div>
  );
}

const FETCH_INSTITUTIONS = gql`
  query {
    getInstitutions {
      id
      name
      address
      location {
        country {
          nombre
        }
        province {
          id
          nombre
          centroide {
            lat
            lon
          }
        }
        city {
          id
          nombre
          centroide {
            lat
            lon
          }
        }
      }
    }
  }
`;
