import jsPDF from "jspdf";
import moment from "moment";
import { CsvBuilder } from "filefy";

export function getPdfLicences(licences) {
  const doc = new jsPDF();
  doc.setFontSize(18);
  doc.setTextColor(40);
  doc.text("Licencias", 10, 10);
  const pdfData = licences.map((rowData) =>
    columns.map((columnDef) => {
      if (columnDef.field == "state") {
        if (rowData["dateExp"] <= moment(new Date()).format("YYYY-MM-DD")) {
          return "Vencida";
        } else {
          return "Activa";
        }
      } else if (columnDef.field == "email") {
        return rowData.user ? rowData.user.email : ".";
      } else {
        return rowData[columnDef.field];
      }
    })
  );

  doc.autoTable({
    head: [
      ["Clave", "Válida hasta", "Tipo de licencia", "Propietario", "Estado"],
    ],
    body: pdfData,
  });

  doc.save(`data.pdf`);
}

export function getCsvLicences(licences) {
  const csvData = licences.map((rowData) =>
    columns.map((columnDef) => {
      if (columnDef.field == "state") {
        if (rowData["dateExp"] <= moment(new Date()).format("YYYY-MM-DD")) {
          return "Vencida";
        } else {
          return "Activa";
        }
      } else if (columnDef.field == "email") {
        return rowData.user ? rowData.user.email : ".";
      } else {
        return rowData[columnDef.field];
      }
    })
  );

  //console.log("csv data: ", csvData);
  //console.log("dataa :: ", data);

  const builder = new CsvBuilder(`licencias.csv`)
    .setColumns([
      "CLAVE",
      "VÁLIDO HASTA",
      "TIPO DE LICENCIA",
      "PROPIETARIO",
      "ESTADO",
    ])
    .addRows(csvData)
    .exportFile();

  return builder;
}

export const columns = [
  { title: "Clave", field: "key" },
  {
    title: "Valido Hasta",
    field: "dateExp",
    render: (rowData) => {
      //console.log('DATE_EXP PASADA PARA VERIFICAR', rowData);
      //const isExpired = getState(rowData.dateExp);
      return <span>{rowData.dateExp}</span>;
    },
  },
  { title: "Tipo de Licencia", field: "typeLicence" },
  {
    title: "Propietario",
    field: "email",
    render: (rowData) => {
      //console.log("USER COLUMN SASA", rowData);
      return rowData.user ? rowData.user.email : <div align="center">-</div>;
    },
  },
  {
    title: "Estado",
    field: "state",
    render: (rowData) => {
      //console.log(rowData);
      const isExpired = getState(rowData.dateExp);

      //console.log("Vencida? ", isExpired);
      if (isExpired) {
        return <span style={{ color: "red" }}>Vencida</span>;
      } else {
        return <span style={{ color: "green" }}>Activa</span>;
      }
    },
  },
];

const getState = (dateExp) => {
  const date = new Date();
  const dateExpLocal = new Date(dateExp);

  var dateCurrent = moment(date).format("YYYY-MM-DD");
  var dateExp = moment(dateExpLocal).format("YYYY-MM-DD");
  //const dateExpLocal = new Date(rowData.dateExp);
  //const isExp = moment(dateCurrent).isAfter(dateExp, 'day'); // true
  if (dateCurrent > dateExp) {
    //console.log(`${dateCurrent} es mayor que ${dateExp}`);
    return true;
  } else {
    //console.log(`AT ${dateCurrent} es menor que ${dateExp}`);
    return false;
  }
};

export const options = {
  exportButton: {
    csv: true,
    pdf: true,
  },
  exportCsv: (columns, data) => {
    getCsvLicences(data);
  },
  exportPdf: (columns, data) => {
    getPdfLicences(data);
  },
};

module.export = {
  getCsvLicences,
  getPdfLicences,
  columns,
  options,
};
