import React, { useContext, useState, useEffect } from "react";
import InstitutionForm from "../../components/InstitutionForm"

export default function CreateInstitution() {


  return (
    <div>
      <InstitutionForm/>
    </div>
  );
}

