import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  Container,
  Header,
  Form,
  Grid,
  Segment,
} from "semantic-ui-react";
import BannerText from "../components/BannerText";
import { Divider, IconButton } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import CardAttempt from "../components/CardAttempt";

import BoxSaveChanges from "../components/activity/BoxCorrect";
import { render } from "@testing-library/react";
import Swal from "sweetalert2";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import routes from "../helpers/routes";
import { useHistory } from "react-router";

export default function Activity(props) {
  const history = useHistory();
  const { user, activity, roomSessionId } = props.location.state;
  const [attemptsToSave, setAttemptsToSave] = useState([]);
  const [isChanges, setIsChanges] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState({
    view: false,
    onSelected: false,
  });
  const [isEditAttempts, setIsEditAttempts] = useState(false);
  const [keyAttempts, setKeyAttempts] = useState(0);
  let newAttempts = [];
  const addAttempt = (attempt) => {
    setIsChanges(true);
    const array = attemptsToSave;
    array.push(attempt);
    setAttemptsToSave(array);
    //console.log("itemss ", attemptsToSave);
  };

  //console.log("user en act: ", user);

  const handleSaveChanges = () => {
    console.log("Save Changes!");

    if (!isEditStatus.onSelected) {
      Swal.fire({
        title: "Atenciòn",
        text: "Debes corregir la actividad antes de guardar los cambios",
        icon: "warning",
      });
      return;
    }

    user.attempts.map((attempt, i) => {
      attemptsToSave.map((attemptToSave, i) => {
        //console.log('aCT ------------- ', attempt)
        if (attemptToSave.id == attempt._id) {
          attempt.state = attemptToSave.state;
          //console.log('ACIERTO DE ID')
        }
      });
      newAttempts.push({
        _id: attempt._id,
        attempt: attempt.attempt,
        state: attempt.state,
        fileUrl: attempt.fileUrl,
        filename: attempt.filename,
        logs: attempt.logs,
        commentary: attempt.commentary,
      });
    });

    // enviar la peticion.
    correctAct();

    /* console.log(
      `RoomsesionId: ${roomSessionId} - userId: ${user._id} activityId: ${activity.id} attempts: ${user.attempts}`
    ); */

    setIsChanges(false);
  };
  const handleDiscardChanges = () => {
    console.log("Discard changes! ");
    setIsChanges(false);
    setIsEditAttempts(false);
    setIsEditStatus({ view: false, onSelected: false });
  };

  function AlertSave() {
    return (
      <div style={{ padding: "1%" }}>
        <BoxSaveChanges
          title="¿Desea guardar los cambios realizados?"
          handleCheck={handleSaveChanges}
          handleFail={handleDiscardChanges}
          color="red"
        />
      </div>
    );
  }

  const [correctAct, { loading }] = useMutation(CORRECT_ACTIVITY, {
    update(_, { data: { correctActivity: roomSessionNew } }) {
      //console.log('Nuevo room session from query _::_ ', roomSessionNew);
      newAttempts = [];
      if (roomSessionNew) {
        //history.push(routes.teacher.board);
        window.location.href = routes.teacher.board;
        Swal.fire({
          title: "Exito",
          text: "Actividad actualizada correctamente!",
          icon: "success",
        });

        return;
      } else {
        Swal.fire({
          title: "Error",
          text: "No se ha podido actualizar la actividad!",
          icon: "error",
        });
        return;
      }

      //context.login(userData);
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log("Otro error", err);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
      newAttempts = [];
    },
    variables: {
      roomSessionId: roomSessionId,
      activityId: activity.id,
      userId: user.user.id,
      attempts: newAttempts,
      status: isEditStatus.onSelected,
    },
  });

  const handleCheckActivity = () => {
    console.log("Aprobar Actividad!");

    setIsEditStatus({ view: false, onSelected: "Acierto" });
  };
  const handleFailActivity = () => {
    console.log("Reprobar Actividad!");

    setIsEditStatus({ view: false, onSelected: "Fallo" });
  };

  const handleEdit = () => {
    if (!isEditStatus.view) {
      setIsEditAttempts(true);
      setIsEditStatus({ view: true, onSelected: false });
    } else {
      setIsEditAttempts(false);
      setIsEditStatus({ view: false });
    }
  };

  useEffect(() => {
    if (keyAttempts == 0) {
      setKeyAttempts(keyAttempts + 1);
    } else {
      setKeyAttempts(keyAttempts - 1);
    }
  }, [isEditStatus.view]);
  //console.log('ACTIVITY sdasd ', activity)

  return (
    <Container
      style={{
        borderRadius: 8,
        boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
        background: "rgba( 255, 255, 255, 0.8 )",
        border: "2px solid rgba( 255, 255, 255, 0.18 ),",
        padding: "20px",
      }}
    >
      {/* <Banner tittle={`${course.name}`}></Banner> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <h1>{`${user.user.lastname}, ${user.user.username} - ${activity.name}`}</h1>
      </div>

      <div>
        <IconButton aria-label="previous" onClick={handleEdit}>
          <EditIcon />

          {user.status == "Acierto" ? (
            <IconButton aria-label="previous">
              Aprobada
              <CheckIcon style={{ fill: "green" }} />
            </IconButton>
          ) : user.status == "Fallo" ? (
            <IconButton aria-label="previous">
              Desaprobada
              <ClearIcon style={{ fill: "red" }} />
            </IconButton>
          ) : (
            <span>
              Corregir
              {/* <HelpOutlineIcon /> */}
            </span>
          )}
        </IconButton>
      </div>

      {isEditStatus.view && (
        <BoxSaveChanges
          title="¿Aprobar Actividad?"
          handleCheck={handleCheckActivity}
          handleFail={handleFailActivity}
          color="black"
        />
      )}

      <h3>Intentos Realizados</h3>

      <div key={keyAttempts}>
        {user.attempts &&
          user.attempts.map((attempt, i) => {
            return (
              <div key={i}>
                <CardAttempt
                  id={attempt._id}
                  nro={attempt.attempt}
                  fileUrl={attempt.fileUrl}
                  filename={attempt.filename}
                  primary={attempt.state}
                  primaryDesc={attempt.commentary}
                  secondaryDesc={attempt.logs}
                  addAttempt={addAttempt}
                  isChanges={isEditAttempts}
                />
              </div>
            );
          })}
        <Divider />

        {(isChanges || isEditStatus.onSelected) && <AlertSave />}
      </div>
      <Divider />
    </Container>
  );
}

const CORRECT_ACTIVITY = gql`
  mutation correctActivity(
    $roomSessionId: ID!
    $activityId: ID!
    $userId: ID!
    $attempts: [AttemptsInput]!
    $status: String!
  ) {
    correctActivity(
      roomSessionId: $roomSessionId
      activityId: $activityId
      userId: $userId
      attempts: $attempts
      status: $status
    ) {
      id
      activities {
        name
        description
        laboratory
        users {
          attempts {
            _id
            attempt
            state
            fileUrl
            logs
            commentary
          }
        }
      }
    }
  }
`;
