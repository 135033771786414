import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import AuthProvider from './auth/AuthProvider';
import AppRouter from './routers/AppRouter';

function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </Router>
    </div>
  );
}


export default App;
