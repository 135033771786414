import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";

import MailIcon from "@material-ui/icons/Mail";
import { useHistory } from "react-router-dom";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import routes from "../../helpers/routes";
import getPagesDrawerByRole from "../../hooks/getPagesDrawerByRole";
import useAuth from "../../auth/useAuth";
import { GetRoleHook } from "../../hooks/getRole";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    minHeight: "84vh",
    minWidth: "230px",
    maxWidth: "400px",
    boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
    background: "rgba( 255, 255, 255, 0.7 )",
    border: "2px solid rgba( 255, 255, 255, 0.18 ),",
    borderRadius: "10px",
  },
}));

export default function MakeshiftDrawer({ open }) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  function handleListItemClick(event, index) {
    setSelectedIndex(index);
  }
  const { getRole, getLicences } = useAuth();
  const licences = getLicences();
  const licenceTeacher = licences.filter(lic => lic.typeLicence == 'DOCENTE');
  console.log('licencia filtrada: ', licenceTeacher);
  const getPages = getPagesDrawerByRole(GetRoleHook());
  //console.log('ROLE: ', roleHook)
  

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <div
        className={classes.root}
        style={{
          /* background: "000" */
          boxShadow: "4px 0px 10px -8px rgba(0,0,0,0.96)",
        }}
      >
        <List>
          {getPages &&
            getPages.map((item, index) => {
              const { text, icon, onClick } = item;
              return (
                <ListItem button key={text} onClick={onClick}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItem>
              );
            })}
        </List>
      </div>
    </Slide>
  );
}
