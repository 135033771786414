import React, { useContext, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import DrawerMenu from "../components/DrawerMenu";
import clsx from "clsx";
import DimmerLab from "../components/DimmerLab";
import BannerHome from "../components/BannerHome";
import Footer from "../components/FooterHome";
import { Link } from "react-router-dom";
import Session from "./Session";
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import Button from "@material-ui/core/Button";

import { AuthContext } from "../context/auth";
import GenerateLicences from "./GenerateLicences";
import RequestLicences from "./RequestLicences";
import RequestsLicencesAdm from "./RequestsLicencesAdm";
import Board from "./Board";
import InstitutionForm from "../components/InstitutionForm";
import ActiveLicence from "./activeLicence";

import Homeimg from "../components/Home-img";

/* import imghome from "../../public/images/licency.jpg"; */
const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export default function MenuAppBar() {
  const pathname = window.location.pathname;
  const path = pathname === "/" ? "home" : pathname.substr(1);
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  const { user, logout } = useContext(AuthContext);
  //console.log("user", user);

  const [itemRender, setItemRender] = useState(false);

  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = useState();
  const openLogin = Boolean(anchorEl);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const laboratories = [
    {
      name: "Licencias",
      urlImg: "images/licency.jpg",
    },
    {
      name: "Instituciones",
      urlImg: "images/school.png",
    },
  ];

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const handleItemDrawerSelected = (item) => {
    //console.log("Item seleccionado ", item);
    setItemRender(item);
  };

  const render = () => {
    switch (itemRender.name) {
      case "viewLicences":
        return <div>ViewLicences</div>;
      case "myReqLicences":
        return <GenerateLicences />;
      case "teacherBoard":
        return <Board handleItemDrawerSelected={handleItemDrawerSelected} />;
      case "reqLicences":
        return <RequestLicences />;
      case "createInstitution":
        return <InstitutionForm />;
      case "reqLicencesAdmin":
        return <RequestsLicencesAdm />;
      case "activeLicence":
        return <ActiveLicence />;
      case "sessions":
        return <Session />;
      default:
        return <div>Ninguno seleccionado</div>;
    }
  };

  return (
    <div className={classes.root}>
      {/* <FormGroup>
        <FormControlLabel
          control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}
      <Homeimg />
    </div>
  );
}

/*
button link
<Link to="/about">
   <Button variant="contained" color="primary">
     About Page
   </Button>
  </Link>
 */
