import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GuestPage from "../pages/guest/Guest";
import AdminPage from "../pages/admin/Home";
import HomePage from "../pages/Home";

import NotFoundPage from "../pages/NotFoundPage";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ChangePasswordMail from "../pages/ChangePasswordMail";

import Layout from "../components/layouts/Layout";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import roles from "../helpers/roles";
import routes from "../helpers/routes";

import RequestLicences from "../pages/RequestLicences";
import GenerateLicences from "../pages/GenerateLicences";

import RequestLicencesAdm from "../pages/RequestsLicencesAdm";
import Board from "../pages/Board";
import ActiveLicence from "../pages/activeLicence";
import Course from "../pages/Course";
import Session from "../pages/Session";
import Activity from "../pages/Activity";
import CreateInstitutions from "../pages/adminOrg/CreateInstitution";
import MyLicences from "../pages/licences/myLicences";
import Confirmation from "../pages/confirmationEmail/Confirmation";

import Institutions from "../pages/institutions/Institutions";
import Institution from "../pages/institutions/Institution";

import MyAccount from "../pages/account/MyAccount";

import LicencesByProvinces from "../pages/LicencesByProvinces";

import CreateCourse from "../pages/course/CreateCourse";
import AssignRole from "../pages/AssignRole";
import ChangePassword from "../pages/account/ChangePassword";

export default function AppRouter() {
  return (
    //<Router>
    <Layout>
      <Switch>
        <PrivateRoute exact path={routes.home} component={HomePage} />
        <PrivateRoute exact path={routes.guest} component={GuestPage} />
        <PrivateRoute
          exact
          path={routes.changePassword}
          component={ChangePassword}
        />

        <PrivateRoute
          exact
          path={routes.activeLicence}
          component={ActiveLicence}
        />
        <PrivateRoute exact path={routes.account} component={MyAccount} />

        {/* admin */}
        <PrivateRoute
          hasRole={roles.admin.name}
          exact
          path={routes.admin.home}
          component={AdminPage}
        />
        <PrivateRoute
          hasRole={roles.admin.name}
          exact
          path={routes.admin.requestLicences}
          component={RequestLicencesAdm}
        />
        <PrivateRoute
          exact
          hasRole={roles.admin.name}
          path={routes.admin.assignManagerInst}
          component={AssignRole}
        />

        {/* <PrivateRoute hasRole={roles.admin.user()} exact path={routes.admin.home} component={AdminPage}/> */}

        {/* admin organism */}
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.requestLicences}
          component={RequestLicences}
        />
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.createInstitution}
          component={CreateInstitutions}
        />
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.assignManagerInst}
          component={AssignRole}
        />
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.listInstitution}
          component={Institutions}
        />
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.institution()}
          component={Institution}
        />
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.myRequests}
          component={RequestLicencesAdm}
        />
        <PrivateRoute
          hasRole={roles.adminOrg.name}
          exact
          path={routes.adminOrg.licencesByProvinces}
          component={LicencesByProvinces}
        />

        {/* teacher role */}
        <PrivateRoute
          exact
          hasRole={roles.teacher.name}
          path={routes.teacher.board}
          component={Board}
        />
        <PrivateRoute
          exact
          hasRole={roles.teacher.name}
          path={routes.teacher.course}
          component={Course}
        />
        <PrivateRoute
          exact
          hasRole={roles.teacher.name}
          path={routes.teacher.session}
          component={Session}
        />
        <PrivateRoute
          exact
          hasRole={roles.teacher.name}
          path={routes.teacher.activity}
          component={Activity}
        />
        <PrivateRoute
          exact
          hasRole={roles.teacher.name}
          path={routes.teacher.createCourse}
          component={CreateCourse}
        />

        {/* adminInst */}
        <PrivateRoute
          hasRole={roles.adminInst.name}
          exact
          path={routes.adminInst.generateLicences}
          component={GenerateLicences}
        />
        <PrivateRoute
          exact
          hasRole={roles.adminInst.name}
          path={routes.adminInst.myLicences}
          component={MyLicences}
        />
        {/* 	<PrivateRoute hasRole={roles.adminInst.name} exact path={routes.adminInst.myLicences} component={MyLicences}/> */}
        {/* <PrivateRoute hasRole={roles.adminInst.name} exact path={routes.adminInst.assignTeacher} component={AssignRole}/> */}

        <PublicRoute exact path={routes.login} component={Login} />
        <PublicRoute exact path={routes.register} component={Register} />
        <PublicRoute exact path={routes.verify()} component={Confirmation} />
        <PublicRoute
          exact
          path={routes.identity}
          component={ChangePasswordMail}
        />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Layout>
    //</Router>
  );
}
