import useAuth from "../auth/useAuth";

export function GetRoleHook() {
  const { getRole, getLicences } = useAuth();
  const licences = getLicences();
  const licenceTeacher = licences.filter((lic) => lic.typeLicence == "DOCENTE");
  console.log("licencia filtrada: ", licenceTeacher);
  if (licenceTeacher[0]?.typeLicence == "DOCENTE") {
    return 'teacher';
  } else if (licenceTeacher[0]?.typeLicence == "ESTUDIANTE") {
    return 'student';
  } else if (!licenceTeacher[0]?.typeLicence && getRole() == "teacher") {
    return 'student';
  } else {
    return getRole();
  }
}
