import React, { useEffect } from "react";
import { Header, Icon, Image } from "semantic-ui-react";
import video1 from "../util/campus.mp4";

const Homeimg = () => {
  useEffect(() => {
    /*    document.getElementById('video').play();*/
  }, []);

  return (
    <div
    // style={{
    //   width: "70%",
    //   overflow: "hidden",
    // }}
    >
      {/* <video className="videoHome" id="video" src={video1} muted loop autoplay width="90%"></video> */}

      <Header as="h2" icon textAlign="center">
        <Icon name="bullhorn" circular style={{ color: "white" }} />
        <Header.Content style={{ color: "white" }}>
          Bienvenido a Fanlab Gestion
        </Header.Content>
      </Header>
    </div>
  );
};

export default Homeimg;
