import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  Header,
  Form,
  Grid,
  Segment,
} from "semantic-ui-react";
import Banner from "../components/BannerText";
import TableActivities from "../components/TableActivities";
import ListStudents from "../components/ListStudents";
import DimmerStatLab from "../components/DimmerStatLab";
import ListStudentsCollapse from "../components/ListStudentsCollapse";
import { Transition } from "semantic-ui-react";

function Course(props) {
  const session = props.location.state;
  const laboratories = [
    {
      name: "Ag-Tech",
      urlImg: "images/agtech.png",
    },
    {
      name: "Ingeniería",
      urlImg: "images/ingenieria.png",
    },
    {
      name: "Robótica",
      urlImg: "images/robotica.png",
    },
    {
      name: "Fisico Química",
      urlImg: "images/fisicoquimica.png",
    },
    {
      name: "Mantenimiento sustentable",
      urlImg: "images/mantenimiento.png",
    },
    {
      name: "Recepción",
      urlImg: "images/recepcion.png",
    },
  ];

  const tittle = "Clase de introduccion ";

  //console.log("received props En sesssionnn", session);
  return (
    <Container
      style={{
        borderRadius: 8,
        boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
        background: "rgba( 255, 255, 255, 0.8 )",
        border: "2px solid rgba( 255, 255, 255, 0.18 ),",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <h1>{session.name + " : " + session.date}</h1>
      </div>
      {/* <Header as="h3" block>
        Desempeño General por Laboratorio
      </Header>
      <Grid container centered stackable verticalAlign="middle">
        {laboratories.map((labo, i) => {
          //console.log(labo);
          return (
            <DimmerStatLab
              key={labo.name}
              lab={labo.name}
              urlImg={labo.urlImg}
            ></DimmerStatLab>
          );
        })}
      </Grid> */}
      <h3>Actividades propuestas</h3>
      <Transition.Group>
        <ListStudentsCollapse
          style={{
            borderRadius: "25px",
          }}
          activities={session?.activities}
          roomSessionId={session.id}
        />
      </Transition.Group>
      {/* <TableActivities activities={session.activities}></TableActivities> */}
      {/* <Header as="h3" block>
        Participantes
      </Header>
      <ListStudents ></ListStudents> */}
    </Container>
  );
}

export default Course;
