import React, { useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import video1 from "./../util/campus.mp4";

import { AuthContext } from "../context/auth";
import { useForm } from "../util/hooks";

import Swal from "sweetalert2";

import { RiLoginCircleFill } from "react-icons/ri";

// login
import useAuth from "../auth/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../helpers/routes";
import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    backdropFilter: "blur(5px)",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backdropFilter: "blur(5px)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#347EBF",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  containerBox: {
    padding: "20px ",
    borderRadius: 8,
    boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
    background: "rgba( 255, 255, 255, 0.7 )",
    border: "2px solid rgba( 255, 255, 255, 0.18 ),",
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#347ebf",
      },
    },
  },
}));

export default function SignIn(props) {
  useEffect(() => {
/*     document.getElementById("video").play();
 */  }, []);

  const classes = useStyles();
  const history = useHistory();

  const context = useContext(AuthContext);

  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    email: "",
    password: "",
  });

  const { login } = useAuth();
  const location = useLocation();
  //console.log('location in login', location);

  function loginUserCallback() {
    console.log(values);
    loginUser();
  }

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(_, { data: { loginWeb: userData } }) {
      //console.log(userData);
      //context.login(userData);
      //console.log('User data login', userData);
      login(userData, location.state?.from);
      //props.history.push('/');
      //window.location.href= '/';
      //history.push('/');
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log("el error :: ", err.message);
      console.log("error de graphql :: ", err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Atención",
        text: err.graphQLErrors[0]?.message,
        icon: "warning",
      });
    },
    variables: values,
  });

  return (
    <Container component="main" maxWidth="xs" className={classes.containerBox}>
      <div style={{ background: "whitesmoke", zIndex: "1" }}>
        <div className="divBlur"></div>

        {/* <video
          className="videoHome"
          id="video"
          src={video1}
          muted
          loop
          autoplay
          width="90%"
        ></video> */}
      </div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <RiLoginCircleFill />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} noValidate>
          <TextField
            className={classes.select}
            style={{
              backgroundColor: "white",
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={onChange}
          />
          <TextField
            className={classes.select}
            style={{
              backgroundColor: "white",
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            style={{ backgroundColor: "#347EBF", color: "white" }}
          >
            Iniciar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={routes.identity} variant="body2">
                Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link href={routes.register} variant="body2">
                {"No tienes una cuenta? Registrate!"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>

      {loading}
      <Box>
        <Copyright />
      </Box>
    </Container>
  );
}

const LOGIN_USER = gql`
  mutation loginWeb($email: String!, $password: String!) {
    loginWeb(email: $email, password: $password) {
      id
      username
      lastname
      email
      licences{
        typeLicence
      }
      password
      token
      role {
        name
      }
      status
    }
  }
`;
