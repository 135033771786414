import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Header,
  Form,
  Grid,
  Segment,
} from "semantic-ui-react";
import Banner from "../components/BannerText";
import DimmerStatLab from "../components/DimmerStatLab";
import SessionCard from "../components/SessionCard";
import Table from "../components/Table";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

import gql from "graphql-tag";
import routes from "../helpers/routes";

function Course(props) {
  const laboratories = [
    {
      name: "Ag-Tech",
      urlImg: "images/agtech.png",
      percentaje: 5,
    },
    {
      name: "Ingeniería",
      urlImg: "images/ingenieria.png",
      percentaje: 30,
    },
    {
      name: "Robótica",
      urlImg: "images/robotica.png",
      percentaje: 50,
    },
    {
      name: "Fisico Química",
      urlImg: "images/fisicoquimica.png",
      percentaje: 100,
    },
    /*     {
          name: "Mantenimiento sustentable",
          urlImg: "images/mantenimiento.png",
        }, */
    {
      name: "Recepción",
      urlImg: "images/recepcion.png",
      percentaje: 90,
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 220,
    },
    {
      field: "date",
      headerName: "Fecha",
      type: "date",
      width: 200,
    },
    {
      field: "roomType",
      headerName: "Tipo",
      width: 200,
    },
    {
      field: "Accion",
      sortable: false,
      width: 250,
      headerAlign: "center",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        /* const onClick = () => {
          console.log('asd', params.row)
          return;
        }; 
        return <Button
          onClick={onClick}
          variant="contained"
          color="blue">
            Ver
            </Button>;*/
        return (
          <span>
            <Link
              to={{
                pathname: routes.teacher.session,
                state: params.row, // your data array of objects
              }}
            >
              <Button
                variant="contained"
                color="blue"
                /* onClick={(event) => {
                  handleClickView(event, params);
                }} */
              >
                VER
              </Button>
            </Link>
            {/* <Button
              variant="contained"
              color="red"
              onClick={(event) => {
                handleClickDelete(event, params);
              }}
            >
              ELIMINAR
            </Button> */}
          </span>
        );
      },
    },
  ];

  const handleClickView = (event, cellValues) => {
    console.log("Ver ", cellValues.row);
  };

  const handleClickDelete = (event, cellValues) => {
    console.log("Eliminar ", cellValues.row);
  };

  const course = props.location.state;
  //console.log('IDS DE SESSIONES: ', course.roomSessions);
  const roomSessionIds = course.roomSessions;

  // peticion

  const { loading, data } = useQuery(FETCH_SESSIONS_BY_IDS_QUERY, {
    variables: { roomSessionIds },
  });

  const { loading: loadingStatistics, data: statistics } = useQuery(
    FETCH_STATISTICS_BY_IDS_QUERY,
    {
      variables: { roomSessionIds },
    }
  );

  /* useEffect(() => {
    console.log("Data fetch", data?.getSessions);
  }, [data]); */

  const tittle = `${course.name}`;
  return (
    <Container
      style={{
        borderRadius: 8,
        boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
        background: "rgba( 255, 255, 255, 0.8 )",
        border: "2px solid rgba( 255, 255, 255, 0.18 ),",
        padding: "20px",
      }}
    >
      {/* <Banner tittle={`${course.name}`}></Banner> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <h1>{tittle}</h1>
      </div>

      <h3>Desempeño General por Laboratorio</h3>

      <Grid container centered celled stackable verticalAlign="middle">
        {/* {laboratories.map((labo, i) => {
          return (
            <Grid.Column width={3}>
              <DimmerStatLab
                key={i}
                lab={labo.name}
                urlImg={labo.urlImg}
              ></DimmerStatLab>
            </Grid.Column>
          );
        })} */}

        {statistics?.getStatisticsByIdsRooms.map((labo, i) => {
          return (
            <Grid.Column
              width={4}
              style={{
                border: "none",
                background: "rgba(52, 126, 191, 0.2)",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                {labo.laboratory}
              </h3>
              <DimmerStatLab
                key={i}
                lab={labo.laboratory}
                /* urlImg={"images/ingenieria.png"} */
                percentaje={labo.perc}
              ></DimmerStatLab>
            </Grid.Column>
          );
        })}
      </Grid>
      <h3>Ultimas Sesiones</h3>
      <div>
        <Segment
          style={{
            border: "none",
            overflow: "hidden",
            maxHeight: 500,
            background: "rgba(0,0,0,0)",
          }}
        >
          {/* {course.roomSessions.map((roomSession, i) => {
          return(
            <SessionCard roomSession={roomSession} key={i}></SessionCard>
            );
          })} */}
          {data && (
            <div
              style={{
                background: "rgba(52, 126, 191, 0.2)",
              }}
            >
              <Table rowsData={data.getSessions} columnsData={columns} />
            </div>
          )}
        </Segment>
      </div>
    </Container>
  );
}

const FETCH_SESSIONS_BY_IDS_QUERY = gql`
  query ($roomSessionIds: [ID]!) {
    getSessions(ids: $roomSessionIds) {
      id
      name
      date
      roomType
      activities {
        id
        name
        description
        laboratory
        users {
          user {
            id
            username
            lastname
          }
          status
          attempts {
            _id
            attempt
            state
            fileUrl
            filename
            logs
            commentary
          }
        }
      }
    }
  }
`;

const FETCH_STATISTICS_BY_IDS_QUERY = gql`
  query ($roomSessionIds: [ID]!) {
    getStatisticsByIdsRooms(roomSessionIds: $roomSessionIds) {
      laboratory
      attempts
      success
      failure
      undef
      perc
    }
  }
`;

export default Course;
