import React, { useContext } from 'react';
import { Button, Card, Icon, Label, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


function PostCard({ id, name, address }) {


  return (
    <Card fluid key={id}>
      <Card.Content>
        {/* <Image
          floated="right"
          size="mini"
          src="https://react.semantic-ui.com/images/avatar/large/molly.png"
        /> */}
        <Card.Header>{name}</Card.Header>
        <Card.Description>{address}</Card.Description>
        
      </Card.Content>
    </Card>
  );
}

export default PostCard;
