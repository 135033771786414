import React, { useContext, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { useMutation } from "@apollo/react-hooks";
//import { AuthContext } from '../context/auth';
import { useForm } from "../util/hooks";
import Container from "@material-ui/core/Container";
import Swal from "sweetalert2";
import Select from "../components/Select";
import typesLicences from "../helpers/typesLicences";
import SelectAsync from "../components/SelectAsync";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles({
  select: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: '#347ebf',
      }
    }
  }
});

function RequestLicences(props) {

  const classes = useStyles();
  //const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [typeSelected, setTypeSelected] = useState(false);
  const [keyTypeSelected, setKeyTypeSelected] = useState(1);
  const [keyInstitutionSelected, setKeyInstitutionSelected] = useState(1);

  const [institutionSelected, setInstitutionSelected] = useState(false);

  const {
    loading: loadingInst,
    error,
    data: institutionsFecth,
  } = useQuery(FETCH_INSTITUTION_QUERY);

  //console.log(institutionsFecth);

  const { onChange, onSubmit, values } = useForm(createRequestLicences, {
    countLicences: "",
    length: "",
  });

  const [addRequest, { loading }] = useMutation(CREATE_REQUEST_LICENCES, {
    update(_, { data: { createRequestLicences: userData } }) {
      //context.login(userData);
      Swal.fire({
        title: "Éxito",
        text: "Solicitud de licencia enviada correctamente!",
        icon: "success",
      });

      clearValues();

      //props.history.push('/');
      //context.login(userData);
      //history.push('/');
    },
    onError(err) {
      console.log("error", err.message);
      Swal.fire({
        title: "Error Internal Server!",
        text: "No se ha podido procesar la Solicitud!",
        icon: "error",
      });
      //debugger;
      //setErrors(err.graphQLErrors[0]?.extensions?.exception?.errors);
    },
    variables: {
      typeLicence: typeSelected.name,
      countLicences: parseInt(values.countLicences),
      length: parseInt(values.length),
      institutionId: institutionSelected.id,
    },
  });

  function createRequestLicences() {
    /* console.log(
      "DATA FORM:; ",
      values,
      typeSelected.name,
      institutionSelected.id
    ); */
    // verificar que se carguen todos los datos.
    if (
      !typeSelected.name ||
      !values.countLicences ||
      !values.length ||
      !institutionSelected.id
    ) {
      Swal.fire({
        title: "Error",
        text: "Complete todos los campos requeridos!",
        icon: "error",
      });
    } else {
      addRequest();
    }
  }

  // fetch institution list
  if (loadingInst) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const clearValues = () => {
    setTypeSelected(false);
    values.countLicences = "";
    values.length = "";
    setInstitutionSelected(false);
    setKeyInstitutionSelected(keyInstitutionSelected + 1);
    setKeyTypeSelected(keyTypeSelected + 1);
  };

  const onChangeTypeLicence = (event, newValue) => {
    //console.log("selected role: ", newValue);
    setTypeSelected({ id: newValue.id, name: newValue.name });
  };

  const onChangeInstitution = (event, newValue) => {
    //console.log("selected role: ", newValue);
    setInstitutionSelected({ id: newValue?.id, name: newValue?.name });
  };

  return (
    <div
      className="form-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Form
        onSubmit={onSubmit}
        noValidate
        className={loading ? "loading" : ""}
        style={{
          background: "rgba( 255, 255, 255, 0.7 )",
          padding: "50px",
          borderRadius: "10px",
          boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
        }}
      >
        <h1>Solicitar Licencias</h1>
        <Form.Field  className={classes.select}>
        <Select
          list={typesLicences}
          label="Tipo de Licencia"
          onChange={onChangeTypeLicence}
          key={keyTypeSelected}
        />
        </Form.Field>
        <Form.Input
          label="Cantidad"
          placeholder="Cantidad.."
          name="countLicences"
          type="text"
          value={values.countLicences}
          error={errors.countLicences ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Duracion (Meses)"
          placeholder="Duracion.."
          name="length"
          type="number"
          value={values.length}
          error={errors.length ? true : false}
          onChange={onChange}
        />
         <Form.Field  className={classes.select}>
        <SelectAsync
          onChange={onChangeInstitution}
          label="Institución"
          loading={loadingInst}
          options={institutionsFecth.getInstitutions}
          key={keyInstitutionSelected}
        />
        </Form.Field>

        <Button type="submit" primary>
          Solicitar
        </Button>
      </Form>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

const CREATE_REQUEST_LICENCES = gql`
  mutation createRequestLicences(
    $typeLicence: String!
    $countLicences: Int!
    $length: Int!
    $institutionId: String!
  ) {
    createRequestLicences(
      input: {
        typeLicence: $typeLicence
        countLicences: $countLicences
        length: $length
        institutionId: $institutionId
      }
    ) {
      id
      typeLicence
      countLicences
      length
      createdAt
      state
    }
  }
`;

const FETCH_INSTITUTION_QUERY = gql`
  query getInstitutions {
    getInstitutions {
      id
      name
    }
  }
`;

export default RequestLicences;
