import roles from "../helpers/roles";
import routes from "../helpers/routes";
import { useHistory } from "react-router-dom";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import InboxIcon from "@material-ui/icons/Inbox";
import { FaBuilding } from "react-icons/fa";
import { FaKey } from "react-icons/fa";
import { MdEmojiPeople } from "react-icons/md";
import {
  IoMdSchool,
  IoIosPeople,
  IoIosPaper,
  IoMdPersonAdd,
  IoMdHome,
} from "react-icons/io";
import { RiAdminFill } from "react-icons/ri";
import { CgExtensionAdd } from "react-icons/cg";
import { AiOutlineBuild, AiOutlineSchedule } from "react-icons/ai";
import { BsShieldFillCheck, BsGeoAltFill } from "react-icons/bs";

export default function MenuIcons(role) {
  //if(!role) return;
  const history = useHistory();
  const pagesAdminOrg = [
    /* {
            text: "Home",
            icon: <IoMdHome color="grey" fontSize="1.8em" />,
            onClick: () => history.push(routes.home)
        }, */
    {
      text: "Crear Institucion",
      icon: <CgExtensionAdd color="grey" fontSize="1.8em" />,
      onClick: () => history.push(routes.adminOrg.createInstitution),
    },
    {
      text: "Ver Instituciones",
      icon: <AiOutlineBuild color="grey" fontSize="1.8em" />,
      onClick: () => history.push(routes.adminOrg.listInstitution),
    },
    {
      text: "Asignar Responsable",
      icon: <IoMdPersonAdd color="grey" fontSize="1.8em" />,
      onClick: () => history.push(routes.adminOrg.assignManagerInst),
    },
    {
      text: "Activar Licencia",
      icon: <BsShieldFillCheck color="grey" fontSize="1.5em" />,
      onClick: () => history.push(routes.activeLicence),
    },
    {
      text: "Solicitar Licencias",
      icon: <VpnKeyIcon />,
      onClick: () => history.push(routes.adminOrg.requestLicences),
    },
    {
      text: "Solicitudes de Licencias",
      icon: <IoIosPaper color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.adminOrg.myRequests),
    },
    {
      text: "Licencias por Provincias",
      icon: <BsGeoAltFill color="grey" fontSize="1.5em" />,
      onClick: () => history.push(routes.adminOrg.licencesByProvinces),
    },
  ];
  const pagesAdmin = [
    /* {
            text: "Admin",
            icon: <RiAdminFill color="grey" fontSize="1.6em"  />,
            onClick: () => history.push(routes.admin.home)
        }, */
    /* {
            text: "Roles",
            icon: <IoIosPeople  color="grey" fontSize="1.6em"  />,
            onClick: () => history.push(routes.admin.home)
        }, */
    {
      text: "Solicitudes",
      icon: <IoIosPaper color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.admin.requestLicences),
    },
    {
      text: "Asignar Responsable",
      icon: <IoMdPersonAdd color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.admin.assignManagerInst),
    },
  ];
  const pagesTeacher = [
    /* {
            text: "Inicio",
            icon:  <MdEmojiPeople color="grey" fontSize="2em"   />,
            onClick: () => history.push(routes.home)
        }, */
    {
      text: "Activar Licencia",
      icon: <FaKey color="grey" fontSize="1.5em" />,
      onClick: () => history.push(routes.activeLicence),
    },
    {
      text: "Mis Instituciones",
      icon: <FaBuilding color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.teacher.board),
    },
    {
      text: "Crear Curso",
      icon: <IoMdSchool color="grey" fontSize="1.8em" />,
      onClick: () => history.push(routes.teacher.createCourse),
    },
  ];

  const pagesStudent = [
    {
      text: "Activar Licencia",
      icon: <FaKey color="grey" fontSize="1.5em" />,
      onClick: () => history.push(routes.activeLicence),
    },
    {
      text: "Mis Instituciones",
      icon: <FaBuilding color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.teacher.board),
    },
  ];

  const pagesAdmInst = [
    /* {
            text: "Inicio",
            icon: <IoMdHome color="grey" fontSize="1.8em" />,
            onClick: () => history.push(routes.home)
        }, */
    {
      text: "Activar Licencia",
      icon: <VpnKeyIcon />,
      onClick: () => history.push(routes.activeLicence),
    },
    {
      text: "Solicitudes",
      icon: <IoIosPaper color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.adminInst.generateLicences),
    },
    {
      text: "Mis Licencias",
      icon: <AiOutlineSchedule color="grey" fontSize="1.6em" />,
      onClick: () => history.push(routes.adminInst.myLicences),
    },
  ];

  const pagesGuest = [
    {
      text: "Home",
      icon: <IoMdHome color="grey" fontSize="1.8em" />,
      onClick: () => history.push(routes.home),
    },
  ];

  //console.log('ROLEEE ',role)

  switch (role) {
    case roles.teacher.name:
      return pagesTeacher;
    case roles.adminInst.name:
      return pagesAdmInst;
    case roles.adminOrg.name:
      return pagesAdminOrg;
    case roles.admin.name:
      return pagesAdmin;
    case roles.student.name:
      return pagesStudent;
    default:
      return pagesGuest;
  }
}
