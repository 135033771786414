import React from "react";


export default function MenuAppBar() {


  return (
    <div>
      Page not Found!
    </div>
  );
}

