import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Grid, Transition, Divider, Button } from "semantic-ui-react";
import InstitutionCard from "../components/ListInstitutions";
import AccordionText from "../components/AccordionText";
import { AuthContext } from "../context/auth";
import { Link } from "react-router-dom";

import ListInst from "../components/ListInst";
import useAuth from "../auth/useAuth";

function Board(props) {
  //const { user } = useContext(AuthContext);
  //const id = user.id;

  const { user } = useAuth();
  const id = user.id;

  const { loading, data } = useQuery(FETCH_USER_INSTITUTION_QUERY, {
    variables: { id },
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <Grid columns={1}>
        <Grid columns={2}></Grid>

        <Divider />
        <Grid.Row>
          {loading ? (
            <h1>Cargando Instituciones..</h1>
          ) : (
            <Transition.Group>
              <ListInst institutions={data?.getUserInstitutions} />
            </Transition.Group>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
}
const FETCH_USER_INSTITUTION_QUERY = gql`
  query ($id: ID!) {
    getUserInstitutions(id: $id) {
      id
      name
      address  
    }
  }
`;

/* const FETCH_USER_QUERY = gql`
query($id: ID!) {
    getUser(_id: $id) {
      licences{
      idInstitution
      nameInstitution
      addressInstitution
     }    
    }
  }
`; */

export default Board;
