import gql from 'graphql-tag';

export const FETCH_POSTS_QUERY = gql`
  {
      getInstitutions{
        id
        name
        address
    }
  }
`;
