import React, { useContext, useState } from "react";
import {
  Icon,
  List,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

function ListCourses({ courses }) {
  const pathname = window.location.pathname;
  const path = pathname === "/" ? "home" : pathname.substr(1);
  const [activeItem, setActiveItem] = useState(path);

  return (
    <List selection verticalAlign="middle">
      {courses &&
        courses.map((course) => (
          <List.Item key={course._id}>
            <Link
              to={{
                pathname: "/Course",
                state: course, // your data array of objects
              }}
            >
              <List.Content floated="right">
              
              <Icon name="angle double right" size="huge"></Icon>
              <List.Content>
              <List.Header>{course.name}</List.Header>
              <List.Description>
              <b></b>
              </List.Description>
              </List.Content>
              </List.Content>
            </Link>
          </List.Item>
        ))}
    </List>
  );
}

export default ListCourses;
