import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import InstitutionEdit from "../../components/institution/InstitutionEdit"

export default function Institution(props) {
    const { institutionId } = useParams();

    const { loading, data, error } = useQuery(GET_INSTITUTION, {
        variables: { institutionId: institutionId }
    });

    if (loading) return <div>Cargando ...</div>
    if (error) return <div>Error</div>



    return (
        <div>
            {data &&
                <div>
                    <InstitutionEdit title='Editar institucion'
                        valuesProp={{
                            id: data.getInstitution.id,
                            name: data.getInstitution.name,
                            address: data.getInstitution.address,
                            provinceName: data.getInstitution.location.province.nombre,
                            cityName: data.getInstitution.location.city.nombre
                        }} />
                </div>}
        </div>
    );
}


const GET_INSTITUTION = gql`
  query getInstitution($institutionId: ID!) {
    getInstitution(_id: $institutionId) {
        id
        name
        address
        location{
            country{
              nombre
            }
            province{
              id
              nombre
            }
            city{
              id
              nombre
            }
          }
    }
  }
`;