import { Redirect, Route, useLocation } from 'react-router-dom';
import useAuth from "../auth/useAuth"
import Drawer from '../components/Drawer';
import routes from '../helpers/routes';

export default function PrivateRoute({ hasRole: role, ...rest }) {
    // para redireccionar a la ruta que se dirigia
    const location = useLocation();
    //console.log(location)

    const { hasRole, isLogged } = useAuth();


    if (!isLogged()) return <Redirect to={{ pathname: routes.login, state: { from: location }}} />;
    if (role && !hasRole(role)) return <Redirect to={routes.account} />;


    return (
        <>
            {/* <Drawer /> */}
            <Route {...rest} />
        </>
    )
}
