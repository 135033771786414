import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import CheckIcon from "@material-ui/icons/Check";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import { Card, Label } from "semantic-ui-react";
import ClearIcon from "@material-ui/icons/Clear";
import { AuthContext } from "../context/auth";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import routes from "../helpers/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "rgba( 255, 255, 255, 0.7 )",
    borderRadius: 8,
    boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList(props) {
  const { activities, roomSessionId } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [activeIndex, setActiveIndex] = useState(-1);
  const [currentCourse, setCurrentCourse] = useState();

  //console.log("room session id en list : ", roomSessionId);

  const handleClick = (i) => {
    /* console.log('id institution: ', idInstitution);
        getInstitution({ variables: { institutionId: idInstitution } });
        if (data) console.log(data); */
    //const newIndex = activeIndex === i ? -1 : i;
    const newIndex = activeIndex === i ? -1 : i;
    setActiveIndex(newIndex);
  };

  const handleClickUser = (user) => {
    console.log(user);
    //console.log('ID DE SESSION;; ', roomSessionId)
    //handleItemDrawerSelected('');
  };

  /* useEffect(() => {
    console.log("Actividades en COllapse :: ", activities);
  }, []); */

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        /* subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Actividades
                    </ListSubheader>
                } */
        className={classes.root}
      >
        {activities &&
          activities.map((activity, i) => (
            <div key={i}>
              <ListItem button onClick={() => handleClick(i)} key={i}>
                <Card
                  fluid
                  key={i}
                  style={{
                    background: "rgba( 255, 255, 255, 0 )",
                  }}
                >
                  <Card.Content>
                    {/* <Image
                                        floated="right"
                                        size="mini"
                                        src="https://react.semantic-ui.com/images/avatar/large/molly.png"
                                        /> */}
                    <Card.Header>{activity.name}</Card.Header>

                    <Card.Meta>
                      <span>{activity.laboratory}</span>
                    </Card.Meta>

                    <Card.Description>{activity.description}</Card.Description>
                  </Card.Content>
                </Card>
                {activeIndex === i ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={activeIndex === i} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {activity?.users &&
                    activity.users.map((user, i) => (
                      <Link
                        key={i}
                        to={{
                          pathname: routes.teacher.activity,
                          state: {
                            user: user,
                            activity: { id: activity.id, name: activity.name },
                            roomSessionId: roomSessionId,
                          }, // your data array of objects
                        }}
                      >
                        <ListItem
                          button
                          className={classes.nested}
                          onClick={() => handleClickUser(user)}
                          key={i}
                        >
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={user.user.lastname + ", " + user.user.username}
                          />
                          <ListItemIcon style={{ paddingRight: "3vw" }}>
                            {user.status === "Acierto" ? (
                              <CheckIcon fontSize="large" />
                            ) : user.status === "Fallo" ? (
                              <ClearIcon fontSize="large" />
                            ) : (
                              <HelpOutlineIcon fontSize="large" />
                            )}
                          </ListItemIcon>
                        </ListItem>
                      </Link>
                    ))}
                </List>
              </Collapse>
            </div>
          ))}
      </List>
    </>
  );
}
