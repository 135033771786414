import React, { useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { useForm } from "../../util/hooks";
import { FETCH_POSTS_QUERY } from "../../util/graphql";
import Swal from "sweetalert2";
import Select from "../Select";
import { useState } from "react";
import { TextField } from "@material-ui/core";

const provincesFetch = require("../../jsonModel/Provinces.json");
const citiesFetch = require("../../jsonModel/Cities.json");

function PostForm(props) {
  const { title, valuesProp } = props;

  const [provinceSelected, setProvinceSelected] = useState(
    provincesFetch.find((prov) => prov.nombre === valuesProp.provinceName)
  );
  const [citySelected, setCitySelected] = useState(
    citiesFetch.find((city) => city.nombre === valuesProp.cityName)
  );
  const [keySelectCity, setKeySelectCity] = useState(1);

  const { values, onChange, onSubmit } = useForm(createInstitutionCallback, {
    name: "",
    address: "",
  });

  const [createInstitution, { error }] = useMutation(CREATE_POST_MUTATION, {
    variables: {
      institutionId: valuesProp.id,
      name: values.name,
      address: values.address,
      provinceId: `${provinceSelected.id}`,
      cityId: `${citySelected.id}`,
    },
    update(proxy, result) {
      Swal.fire({
        title: "Success",
        text: "Institucion Editada correctamente!",
        icon: "success",
      });
      /* const data = proxy.readQuery({
        query: FETCH_POSTS_QUERY
      });
      data.getInstitutions = [result.data.createInstitution,
         ...data.getInstitutions];
      proxy.writeQuery({ query: FETCH_POSTS_QUERY, data }); */
      setKeySelectCity(keySelectCity + 1);
      //console.log("deberia renderizar de nuevo");
    },
    onError(err) {
      //setErrors(err.graphQLErrors[0].extensions.exception.errors);
      console.log(err.graphQLErrors[0]?.message);
      Swal.fire({
        title: "Error",
        text: err.graphQLErrors[0]?.message,
        icon: "error",
      });
    },
  });

  function createInstitutionCallback() {
    /* console.log(
      "completo todos los campos",
      values.name,
      values.address,
      provinceSelected.id,
      citySelected.id
    ); */

    if (
      !values.name ||
      !values.address ||
      !provinceSelected?.id ||
      !citySelected?.id
    ) {
      //console.log();
      //console.log('completo todos los campos', values.name, values.address, provinceSelected.id, citySelected.id)

      Swal.fire({
        title: "Error",
        text: "Por favor rellenar todos los campos requeridos!",
        icon: "error",
      });
    } else {
      createInstitution();
      /* console.log(
        "completo todos los campos",
        values.name,
        values.address,
        provinceSelected.id,
        citySelected.id
      ); */
    }
  }

  useEffect(() => {
    values.name = valuesProp.name;
    values.address = valuesProp.address;
  }, []);

  const onChangeProvince = (event, newValue) => {
    //console.log("selectedeeeee: ", newValue);
    setCitySelected(false);
    setKeySelectCity(keySelectCity + 1);
    setProvinceSelected({ id: newValue?.id, name: newValue?.nombre });
  };

  const onChangeCity = (event, newValue) => {
    //console.log("selected City: ", newValue);
    setCitySelected({ id: newValue?.id, name: newValue?.nombre });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      <div
        style={{
          padding: "20px ",
          borderRadius: 8,
          boxShadow: "5px 2px 14px -8px rgba(0,0,0,0.81)",
          background: "rgba( 255, 255, 255, 0.7 )",
          border: "2px solid rgba( 255, 255, 255, 0.18 ),",
          minWidth: "500px",
        }}
      >
        <Form onSubmit={onSubmit}>
          <h2>{title}</h2>
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre"
              name="name"
              autoFocus
              onChange={onChange}
              defaultValue={valuesProp.name}
              error={error ? true : false}
            />
            <Form.Field>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="address"
                label="Dirección"
                name="address"
                autoFocus
                onChange={onChange}
                error={error ? true : false}
                defaultValue={valuesProp.address}
              />
            </Form.Field>

            <Form.Field>
              <Select
                label="Provincia"
                list={provincesFetch}
                onChange={onChangeProvince}
                defaultValue={provinceSelected}
              />
            </Form.Field>

            <Form.Field>
              <Select
                label="Localidad"
                list={citiesFetch.filter(function (localidad) {
                  return localidad.provincia.id === provinceSelected.id;
                })}
                onChange={onChangeCity}
                defaultValue={citySelected}
                key={keySelectCity}
              />
            </Form.Field>
          </div>

          <Button
            type="submit"
            style={{
              background: "#347ebf",
              color: "whitesmoke",
            }}
          >
            Editar
          </Button>
          <br></br>
        </Form>
      </div>
      {error && (
        <div className="ui error message" style={{ marginBottom: 20 }}>
          <ul className="list">
            <li>{error.message}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

const CREATE_POST_MUTATION = gql`
  mutation updateInstitution(
    $institutionId: ID!
    $name: String!
    $address: String!
    $provinceId: String!
    $cityId: String!
  ) {
    updateInstitution(
      institutionId: $institutionId
      institution: {
        name: $name
        address: $address
        location: { provinceId: $provinceId, cityId: $cityId }
      }
    ) {
      id
      name
      address
    }
  }
`;

export default PostForm;
