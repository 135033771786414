import React from "react";
import { Container, Divider, Image, List, Segment } from "semantic-ui-react";
import Copyright from "../Copyright";

export default function FooterHome() {
  return (
    <Segment
      inverted
      vertical
      style={{
        position: "relative",
        width: "100%",
        bottom: "0%",
        padding: "0",
        background: "#363636",
        minHeight: "7vh",
        display: "flex",

        alignItems: "center",
        zIndex: "999",
      }}
    >
      <Container textAlign="center">
        {/* <Grid divided inverted stackable>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content='Group 1' />
                        <List link inverted>
                            <List.Item as='a'>Link One</List.Item>
                            <List.Item as='a'>Link Two</List.Item>
                            <List.Item as='a'>Link Three</List.Item>
                            <List.Item as='a'>Link Four</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content='Group 2' />
                        <List link inverted>
                            <List.Item as='a'>Link One</List.Item>
                            <List.Item as='a'>Link Two</List.Item>
                            <List.Item as='a'>Link Three</List.Item>
                            <List.Item as='a'>Link Four</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content='Group 3' />
                        <List link inverted>
                            <List.Item as='a'>Link One</List.Item>
                            <List.Item as='a'>Link Two</List.Item>
                            <List.Item as='a'>Link Three</List.Item>
                            <List.Item as='a'>Link Four</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Header inverted as='h4' content='Footer Header' />
                        <p>
                            Extra space for a call to action inside the footer that could help re-engage users.
                        </p>
                    </Grid.Column>
                </Grid> */}
        {/*         <Image centered size="medium" src="/images/FANLAB-vector.svg" /> */}

        {/* <Divider inverted section /> */}
        {/* <Image centered size='small' src='/images/fanlab.png' /> */}
        <List horizontal inverted divided link size="small">
          <List.Item as="a" href="#">
            Sitio Web
          </List.Item>
          <List.Item as="a" href="#">
            Contáctanos
          </List.Item>
          <List.Item as="a" href="#">
            Términos y Condiciones
          </List.Item>
          <List.Item>
            <Copyright />
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}
