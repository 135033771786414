import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Grid, Transition, Divider } from "semantic-ui-react";
import CardRequestLicences from "../components/CardRequestLicences";
import { AuthContext } from "../context/auth";
import useAuth from "../auth/useAuth";
import SearchAppBar from "../components/SearchBar";

function GenerateLicences(props) {
  const { user } = useAuth();
  const { loading, data } = useQuery(FETCH_REQUESTLICENCES_QUERY, {
    variables: {
      institutionsIds: user?.role?.institutions,
    },
  });

  //if (user) console.log("as", user.role.institutions);
  return (
    <div
      className="form-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        height: "80vh",
      }}
    >
      {/* <SearchAppBar /> */}
      <Grid
        columns={3}
        style={{
          marginLeft: "5%",
        }}
      >
        <Grid.Row className="page-title">
          <h1>Solicitudes de licencias</h1>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          {loading ? (
            <h1>Loading Licences..</h1>
          ) : (
            <Transition.Group>
              {data?.getRequestsLicencesByInst &&
                data?.getRequestsLicencesByInst.map((requestsLicences) => (
                  <Grid.Column
                    key={requestsLicences.id}
                    style={{ marginBottom: 20, width: 300 }}
                  >
                    <CardRequestLicences
                      key={requestsLicences.id}
                      requestsLicences={requestsLicences}
                    />
                  </Grid.Column>
                ))}
            </Transition.Group>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
}

const FETCH_REQUESTLICENCES_QUERY = gql`
  query getRequestsLicencesByInst($institutionsIds: [ID]!) {
    getRequestsLicencesByInst(institutionsIds: $institutionsIds) {
      id
      typeLicence
      countLicences
      countReq
      length
      createdAt
      state
      institution{
        name
      }
    }
  }
`;

export default GenerateLicences;
