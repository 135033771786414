import React, { useState, useEffect } from "react";
import { Accordion, Icon, Grid } from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import ListCourses from './listCourses';

const AccordionExampleStandard = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  

  const [accordionData, setAccordionData] = useState(0);

  const { institutionId } = props;

  useEffect(() => {
    // obtenerDatosAcordion()
  }, [institutionId]);

  const { loading, data } = useQuery(FETCH_INSTITUTION_QUERY, {
    variables: { institutionId },
  });

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Cursos
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        { data && (
            <ListCourses courses={data.getInstitution.courses}></ListCourses>
          )}
        <Grid.Column key={institutionId} style={{ marginBottom: 20 }}>
        </Grid.Column>
      </Accordion.Content>
    </Accordion>
  );
};

const FETCH_INSTITUTION_QUERY = gql`
  query($institutionId: ID!) {
    getInstitution(_id: $institutionId) {
      courses{
      _id
      name
      managerId
    }
    }
  }
`;

export default AccordionExampleStandard;
