import React from "react";
import {
  Button,
  Card,
  Icon,
  Label,
  Modal,
  Form,
  Header,
} from "semantic-ui-react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useForm } from "../util/hooks";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import Swal from "sweetalert2";
import roles from "../helpers/roles";
import moment from "moment";
function CardRequestLicences({
  requestsLicences: {
    id,
    typeLicence,
    countLicences,
    countReq,
    length,
    createdAt,
    state,
    institution
  },
  user,
}) {
  const [acceptReqLicences, { error }] = useMutation(ACCEPT_REQUEST_LICENCES, {
    variables: { requestLicenceId: id },
  });

  const [open, setOpen] = React.useState(false);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const handleAcceptReqLicences = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Esta seguro que desea aceptar esta licencia?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, aceptar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          acceptReqLicences()
            .then((resp) => {
              //console.log(resp);
              swalWithBootstrapButtons.fire(
                "Exito!",
                "La Solicitud de licencia ha sido aceptada correctamente!",
                "success"
              );
            })
            .catch((e) => {
              console.log(e);
              /*
        console.log();
        console.log(e.errors);
        debugger;
          */
              //alert(e.graphQLErrors[0].message);
              swalWithBootstrapButtons.fire(
                "Ops!",
                `${e.graphQLErrors[0].message}`,
                "error"
              );
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", "", "warning");
        }
      });

    return;

    /*
    createLicences();
    setOpen(false);
    */
  };
  return (
    <Card>
      <SemanticToastContainer />
      <Label as="a" width="%100">
        Periodo de validéz: {length} meses
      </Label>
      <Card.Content>
        <Card.Header>{typeLicence}</Card.Header>
        <Card.Meta>{'Fecha de solicitud: ' + moment(createdAt).format("YYYY-MM-DD")}</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          {state == "accepted" ? (
            <Button basic color="teal">
              Aceptada
            </Button>
          ) : (
            <Button basic color="red">
              Solicitada
            </Button>
          )}
        </div>
      </Card.Content>
      <Card.Content extra>
        <a>  {countLicences} / {countReq} Disponibles</a>
      </Card.Content>
      {user.role.name == roles.admin.name && (
        <div className="ui two buttons">
          {state == "accepted" ? (
            <Button disabled>Aceptada</Button>
          ) : (
            <Button onClick={handleAcceptReqLicences}>Aceptar</Button>
          )}
        </div>
      )}
    </Card>
  );
}

const ACCEPT_REQUEST_LICENCES = gql`
  mutation acceptRequestLicences($requestLicenceId: ID!) {
    acceptRequestLicences(requestLicenceId: $requestLicenceId) {
      id
      typeLicence
      countLicences
      length
      createdAt
      state
    }
  }
`;

export default CardRequestLicences;
