import React, { useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../react-leaflet.css";
import { divIcon } from "leaflet";
import { iconPerson } from "./IconMarker";
import { renderToStaticMarkup } from "react-dom/server";

import "./styles.css";

function Map({ center, zoomLevel, list }) {
  const redOptions = { color: "red" };

  const iconMarkup = renderToStaticMarkup(
    <i className=" fa fa-map-marker-alt fa-3x" />
  );

  //console.log("recibo: : : ", list);
  return (
    <MapContainer center={center} zoom={zoomLevel} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {list?.map((item, i) => {
        //console.log("paso 1 vez -- ", item.province.centroide.lon);

        const icon = divIcon({
          className: "marker",
          html: `<span>${item.total}</span>`,
        });

        return (
          <CircleMarker
            key={i}
            center={[item.province.centroide.lat, item.province.centroide.lon]}
            pathOptions={{ color: "red" }}
            radius={10}
          >
            <Tooltip direction="bottom" offset={[0, 5]} opacity={1} permanent>
              {item.total}
            </Tooltip>
            <Tooltip direction="bottom" offset={[0, 5]} opacity={1}>
              {item.total}
            </Tooltip>
            {/* <Tooltip>Tooltip for CircleMarker</Tooltip> */}
          </CircleMarker>
        );
      })}
    </MapContainer>
  );
}

export default Map;
