import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BorderColorIcon from "@material-ui/icons/BorderColor";

import BoxCorrectActivity from "./activity/BoxCorrect";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import { Header } from "semantic-ui-react";

import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba( 255, 255, 255, 0.6 )",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const {
    id,
    nro,
    primary,
    secondary,
    image,
    filename,
    description,
    primaryDesc,
    secondaryDesc,
    addAttempt,
    isChanges,
  } = props;
  /* console.log(
    "props in CardActivity: ",
    id,
    nro,
    primary,
    secondary,
    image,
    description
  ); */

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [onClicked, setOnClicked] = useState(false);

  const [getImageAttempt, { loading, error, data }] =
    useLazyQuery(GET_IMAGE_ATTEMPT);

  /* 
  if (loading) return <p>Loading ...</p>;
  if (error) return `Error! ${error}`;
 */

  const handleExpandClick = () => {
    setExpanded(!expanded);
    // buscar la url publica de la imagen dado el nombre
    if(filename) getImageAttempt({ variables: { key: filename } });
  };

  const handleCheckAttempt = () => {
    setOnClicked(true);
    addAttempt({ id: id, state: "Acierto" });
  };
  const handleFailAttempt = () => {
    setOnClicked(true);
    addAttempt({ id: id, state: "Fallo" });
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          /* <Avatar aria-label="recipe" className={classes.avatar}>
            {nro}
          </Avatar> */
          primary === "Indefinido" ? (
            <HelpOutlineIcon fontSize="large" style={{ fill: "grey" }} />
          ) : primary === "Acierto" ? (
            <CheckIcon fontSize="large" style={{ fill: "green" }} />
          ) : (
            <ClearIcon fontSize="large" style={{ fill: "red" }} />
          )
        }
        action={
          isChanges &&
          !onClicked && (
            <BoxCorrectActivity
              title={"Correcto?"}
              handleCheck={handleCheckAttempt}
              handleFail={handleFailAttempt}
            />
          )
        }
        title={"Intento nº " + nro}
        /* subheader="September 14, 2016" */
      />
      {/* Condicional imagen */}
      {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <div
            style={{
              "white-space": "pre-line",
              "overflow-y": "scroll",
              maxHeight: 300,
            }}
          >
            {secondaryDesc}
          </div>
        </Typography>
        <Header as="h5">Comentario del estudiante</Header>
        <i>{primaryDesc}</i>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}
        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <AttachFileIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Header as="h4">Imagen Adjunta</Header>
          <div align="center">
            {loading ? (
              <div align="center">Cargando...</div>
            ) : data?.getImage ? (
              <img src={data.getImage} width="100%" />
            ) : (
              error && (
                <div align="center">
                  <h5>{error?.graphQLErrors[0]?.message}</h5>
                </div>
              )
            )}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
}

const GET_IMAGE_ATTEMPT = gql`
  query getImage($key: String!) {
    getImage(key: $key)
  }
`;
