import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';




export default function MediaControlCard({ title, handleCheck, handleFail, color }) {

    const useStyles = makeStyles((theme) => ({
        root: {
            'box-shadow': `1px 1px 13px ${color}`
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: '1 0 auto',
            textAlign: 'center',
            paddingBottom: '0px'
        },
        cover: {
            width: 151,
        },
        controls: {
            display: 'flex',
        },
        playIcon: {
            height: 38,
            width: 38,
        },
    }));

    
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card className={classes.root}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography variant="subtitle1" color="textSecondary">
                        {title}
                    </Typography>
                </CardContent>
                <div align="center">
                    <IconButton aria-label="previous" onClick={handleCheck}>
                        <CheckIcon style={{ fill: "green" }} />
                    </IconButton>
                    <IconButton aria-label="next" onClick={handleFail}>
                        <ClearIcon style={{ fill: "red" }} />
                    </IconButton>
                </div>
            </div>
            
            {/* <CardMedia
                className={classes.cover}
                src="/images/torno.jpg"
                title="Live from space album cover"
            /> */}
        </Card>
    );
}